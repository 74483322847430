import axios from "axios";
import { alert, clearSession, getRefreshToken, setSession } from "../../utils";
import { IEntity } from "./types";

import { http } from "../../services";
import { setRegisterInfo, setforgotPasswordInfo } from "../../redux";

const url = process.env.REACT_APP_BASE_URL as string;
const refresh = getRefreshToken();

export const signUp = async (
  phoneNumber: string,
  password: string,
  setLoading?: any,
  dispatch?: any,
  navigate?: any
) => {
  const formatNumber = phoneNumber.replace(/\D/g, "");

  try {
    setLoading(true);
    await axios.post(`${url}/user/register/`, {
      username: formatNumber,
      password,
    });
    dispatch(setRegisterInfo({ username: formatNumber, password }));
    navigate("/auth/verification");
  } catch (error: any) {
    console.log(error);
    alert.error("" + error.response.data[0]);
    if (error.response.status === 409) {
      navigate("/auth/verification");
      alert.warning(error.response.data);
    }
  } finally {
    setLoading(false);
  }
};

export const signIn = async (
  phoneNumber: string,
  password: string,
  setLoading: any
) => {
  const formatNumber = phoneNumber.replace(/\D/g, "");

  try {
    setLoading(true);
    const response = await axios.post(`${url}/token/`, {
      username: formatNumber,
      password,
    });
    setSession(response.data);
    window.location.href = "/";
  } catch (error: any) {
    console.error(error);
    alert.error(error.response.data.detail);
  } finally {
    setLoading(false);
  }
};

export const forgotPassword = async (
  phoneNumber: string,
  // setLoading: any,
  dispatch: any,
  navigate: any
) => {
  const formatNumber = phoneNumber.replace(/\D/g, "");
  try {
    // setLoading(true);
    await axios.post(`${url}/user/recovery/`, {
      phone_number: formatNumber,
    });
    dispatch(setforgotPasswordInfo({ phone_number: formatNumber }));
    navigate("/auth/forget-verify");
  } catch (error: any) {
    // setLoading(false);
    alert.error(error.response.data);
  }
};

export const forgotVerify = async (
  phoneNumber: string,
  code: string,
  dispatch: any,
  navigate: any
) => {
  const formatNumber = phoneNumber.replace(/\D/g, "");
  try {
    await axios.put(`${url}/user/recovery/`, {
      phone_number: formatNumber,
      code,
    });
    dispatch(setforgotPasswordInfo({ phone_number: formatNumber, code }));
    navigate("/auth/forget-new-password");
  } catch (error: any) {
    alert.error("Sms kod xato kiritildi!");
  }
};

export const newPasswordForgot = async (
  phone_number: string,
  password: string,
  code: string,
  navigate: any
) => {
  const formatNumber = phone_number.replace(/\D/g, "");
  try {
    await axios.post(`${url}/user/set_password/`, {
      phone_number: formatNumber,
      password,
      code,
    });
    alert.success("Parolingiz o'zgartildi");
    navigate("/auth/login");
  } catch (error: any) {
    alert.error(error.response.data);
  }
};

export const verify = async (
  phoneNumber: string,
  password: string,
  smsCode: string,
  setLoading: any,
  navigate: any
) => {
  try {
    setLoading(true);
    const response = await axios.post(`${url}/user/user_verification/`, {
      username: phoneNumber,
      password,
      smsCode,
    });
    setSession(response.data);
    // window.location.href = "/";
    navigate("/");
  } catch (error: any) {
    alert.error("" + error.response.data);
  } finally {
    setLoading(false);
  }
};

export const signInWithGoogle = async (token: string) => {
  try {
    const response = await axios.post(`${url}/user/google_register/`, {
      token,
    });
    setSession(response.data);
    window.location.href = "/";
  } catch (error: any) {
    console.error(error);
    alert.error(error.response.data.detail);
  }
};

export const googleAccountConnectToPhoneNumber = async (token: string) => {
  try {
    await http.post(`${url}/user/goole_to_phone/`, {
      token,
    });
    alert.success("Google Account Qo'shildi");
  } catch (error: any) {
    console.error(error);
    alert.error(error.response.data.detail);
  }
};

export const signInWithFacebook = async () => {
  alert.warning("Tez orada!!!");
};

export const getMe = async () => {
  try {
    const response = await http.get(`/user`);
    return response.data;
  } catch (error) {
    await refreshToken();
    const response = await http.get(`/user`);
    return response.data;
  }
};

export const refreshToken = async () => {
  try {
    const response = await http.post(`${url}/refresh_token/`, {
      refresh: refresh,
    });
    setSession(response.data);
    window.location.reload();
  } catch (error) {
    alert.error(
      "Refresh token vaqti tugadi, iltimos hisobingizga qaytadan kiring!"
    );
    clearSession();
    window.location.reload();
    throw error;
  }
};

export const editUser = async (userData: IEntity.User, setLoading: any) => {
  try {
    setLoading(true);
    await http.put(`${url}/user/`, { ...userData });
    alert.success("Ma'lumotlar muvaffiyaqatli o'zgartirildi!");
  } catch (error: any) {
    alert.error("" + error.response);
  } finally {
    setLoading(false);
  }
};
