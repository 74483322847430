import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { Footer, Header } from "../../../components";
import { Container, Flex } from "@mantine/core";
import MySettings from "./my-settings";

import Style from "../my-profile.module.scss";

export default function Call() {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Header />
      <Container size={"85rem"}>
        <div className={Style.myProfileWrapper}>
          <p className={Style.sectionTitle}>Mening hisobim</p>
          <div className={Style.sectionChange}>
            <div onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
              >
                <path
                  d="M7.66667 15L1 8M1 8L7.66667 1M1 8H17"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className={Style.sectionTitleChange}>Sozlamalar</p>
          </div>
          <div className={Style.profileLinks}>
            <div>
              <Flex gap={30} mt={10}>
                <a
                  onClick={() => navigate("/myProfile/reports")}
                  className={Style.profileOption}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M21.1799 4C21.7543 4 22.2199 4.47011 22.2199 5.05V8.99485L20.1399 11.0948V6.1H5.57999V22.9H20.1399V20.0041L22.2199 17.9041V23.95C22.2199 24.5299 21.7543 25 21.1799 25H4.54C3.96563 25 3.5 24.5299 3.5 23.95V5.05C3.5 4.47011 3.96563 4 4.54 4H21.1799ZM23.0292 11.148L24.5 12.6329L16.4107 20.8L14.9378 20.7978L14.9399 19.3151L23.0292 11.148ZM13.9 14.5V16.6H8.69998V14.5H13.9ZM17.0199 10.3V12.4H8.69998V10.3H17.0199Z"
                      fill="#889098"
                    />
                  </svg>
                  <a>E’lonlarim</a>
                </a>

                <a
                  onClick={() => navigate("/myProfile/wishes")}
                  className={Style.profileOption}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="21"
                    viewBox="0 0 24 21"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.0325 3.10371C4.01406 2.11891 5.34516 1.56567 6.73308 1.56567C8.121 1.56567 9.4521 2.11891 10.4337 3.10371L11.9673 4.64156L13.5009 3.10371C13.9837 2.60199 14.5613 2.20179 15.1999 1.92648C15.8385 1.65117 16.5253 1.50626 17.2203 1.5002C17.9153 1.49414 18.6045 1.62705 19.2478 1.89118C19.8911 2.15531 20.4755 2.54537 20.9669 3.0386C21.4584 3.53183 21.847 4.11835 22.1102 4.76394C22.3734 5.40953 22.5058 6.10126 22.4998 6.79876C22.4938 7.49627 22.3494 8.18558 22.0751 8.82648C21.8007 9.46738 21.402 10.047 20.9021 10.5316L11.9673 19.5L3.0325 10.5316C2.05124 9.54652 1.5 8.21061 1.5 6.81767C1.5 5.42473 2.05124 4.08882 3.0325 3.10371V3.10371Z"
                      stroke="#889098"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <a>Istaklarim</a>
                </a>

                <a
                  onClick={() => navigate("/myProfile/cart")}
                  className={Style.profileOption}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M3.5 3C2.94772 3 2.5 3.44772 2.5 4C2.5 4.55228 2.94772 5 3.5 5V3ZM6.00746 4L6.97335 3.74103C6.85615 3.30392 6.46001 3 6.00746 3V4ZM10.0821 19.1973L9.1162 19.4563C9.24563 19.939 9.71154 20.2521 10.2074 20.1895L10.0821 19.1973ZM22.6194 17.6143L22.7447 18.6064C23.1696 18.5527 23.5131 18.2345 23.5991 17.8149L22.6194 17.6143ZM24.5 8.43255L25.4797 8.63321C25.5399 8.33891 25.4647 8.03311 25.2748 7.80036C25.0849 7.5676 24.8004 7.43255 24.5 7.43255V8.43255ZM3.5 5H6.00746V3H3.5V5ZM10.2074 20.1895L22.7447 18.6064L22.4941 16.6222L9.95682 18.2052L10.2074 20.1895ZM23.5991 17.8149L25.4797 8.63321L23.5203 8.2319L21.6397 17.4136L23.5991 17.8149ZM5.04158 4.25897L6.23001 8.69152L8.16178 8.17359L6.97335 3.74103L5.04158 4.25897ZM6.23001 8.69152L9.1162 19.4563L11.048 18.9384L8.16178 8.17359L6.23001 8.69152ZM24.5 7.43255H7.1959V9.43255H24.5V7.43255ZM13 23.3427C13 23.7153 12.7037 24 12.3594 24V26C13.8272 26 15 24.8008 15 23.3427H13ZM12.3594 24C12.0151 24 11.7188 23.7153 11.7188 23.3427H9.71875C9.71875 24.8008 10.8915 26 12.3594 26V24ZM11.7188 23.3427C11.7188 22.9702 12.0151 22.6855 12.3594 22.6855V20.6855C10.8915 20.6855 9.71875 21.8847 9.71875 23.3427H11.7188ZM12.3594 22.6855C12.7037 22.6855 13 22.9702 13 23.3427H15C15 21.8847 13.8272 20.6855 12.3594 20.6855V22.6855ZM21.75 23.3427C21.75 23.7153 21.4537 24 21.1094 24V26C22.5772 26 23.75 24.8008 23.75 23.3427H21.75ZM21.1094 24C20.7651 24 20.4688 23.7153 20.4688 23.3427H18.4688C18.4688 24.8008 19.6415 26 21.1094 26V24ZM20.4688 23.3427C20.4688 22.9702 20.7651 22.6855 21.1094 22.6855V20.6855C19.6415 20.6855 18.4688 21.8847 18.4688 23.3427H20.4688ZM21.1094 22.6855C21.4537 22.6855 21.75 22.9702 21.75 23.3427H23.75C23.75 21.8847 22.5772 20.6855 21.1094 20.6855V22.6855Z"
                      fill="#889098"
                    />
                  </svg>
                  <a>Savatim</a>
                </a>

                <a
                  onClick={() => navigate("/myProfile/settings")}
                  className={Style.profileOptionActive}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.9557 5.53565C15.4569 3.48812 12.5431 3.48812 12.0443 5.53565C11.9698 5.84332 11.8238 6.12907 11.618 6.36964C11.4123 6.61021 11.1526 6.79879 10.8602 6.92006C10.5678 7.04132 10.2509 7.09183 9.93524 7.06748C9.61962 7.04313 9.3142 6.9446 9.04384 6.77992C7.24304 5.68265 5.18235 7.74331 6.27963 9.54409C6.98841 10.707 6.3597 12.2243 5.03666 12.5458C2.98778 13.0433 2.98778 15.9584 5.03666 16.4545C5.34442 16.5291 5.63022 16.6753 5.87079 16.8812C6.11136 17.0871 6.2999 17.3469 6.42105 17.6395C6.54219 17.932 6.59253 18.2491 6.56796 18.5648C6.54338 18.8805 6.44459 19.1859 6.27963 19.4562C5.18235 21.257 7.24304 23.3177 9.04384 22.2204C9.31415 22.0554 9.61959 21.9567 9.9353 21.9321C10.251 21.9075 10.5681 21.9579 10.8606 22.079C11.1532 22.2001 11.413 22.3887 11.619 22.6292C11.8249 22.8698 11.9711 23.1556 12.0456 23.4634C12.5431 25.5122 15.4582 25.5122 15.9544 23.4634C16.0292 23.1558 16.1755 22.8702 16.3815 22.6298C16.5874 22.3894 16.8472 22.201 17.1397 22.0798C17.4322 21.9587 17.7491 21.9083 18.0648 21.9328C18.3804 21.9572 18.6858 22.0557 18.9562 22.2204C20.757 23.3177 22.8177 21.257 21.7204 19.4562C21.5557 19.1859 21.4571 18.8805 21.4327 18.5649C21.4083 18.2492 21.4587 17.9323 21.5798 17.6398C21.7009 17.3473 21.8893 17.0876 22.1297 16.8816C22.3701 16.6756 22.6557 16.5293 22.9633 16.4545C25.0122 15.9571 25.0122 13.042 22.9633 12.5458C22.6556 12.4713 22.3698 12.3251 22.1292 12.1192C21.8886 11.9132 21.7001 11.6534 21.579 11.3609C21.4578 11.0683 21.4075 10.7512 21.432 10.4355C21.4566 10.1198 21.5554 9.81439 21.7204 9.54409C22.8177 7.74331 20.757 5.68265 18.9562 6.77992C18.6858 6.94488 18.3804 7.04367 18.0647 7.06824C17.749 7.09281 17.4319 7.04248 17.1394 6.92133C16.8468 6.80018 16.587 6.61165 16.381 6.37108C16.1751 6.13052 16.0289 5.84472 15.9544 5.53696L15.9557 5.53565Z"
                      stroke="#fac71a"
                      stroke-width="2"
                    />
                    <path
                      d="M16.3333 14.5C16.3333 15.7887 15.2887 16.8333 14 16.8333C12.7113 16.8333 11.6667 15.7887 11.6667 14.5C11.6667 13.2113 12.7113 12.1667 14 12.1667C15.2887 12.1667 16.3333 13.2113 16.3333 14.5Z"
                      stroke="#fac71a"
                      stroke-width="2"
                    />
                  </svg>
                  <a>Sozlamalar</a>
                </a>
              </Flex>
              <MySettings />
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
