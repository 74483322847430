export default function NotReportSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244"
      height="224"
      viewBox="0 0 244 224"
      fill="none"
    >
      <g clip-path="url(#clip0_598_4311)">
        <path
          d="M41.5391 119.513C41.5391 121.351 43.0339 122.843 44.8769 122.843C46.7199 122.843 48.2147 121.351 48.2147 119.513C48.2147 117.674 46.7199 116.183 44.8769 116.183C43.0339 116.183 41.5391 117.674 41.5391 119.513Z"
          fill="#F5F5F5"
        />
        <path
          d="M121.207 19.663C121.207 21.5017 122.702 22.993 124.545 22.993C126.388 22.993 127.883 21.5017 127.883 19.663C127.883 17.8244 126.388 16.333 124.545 16.333C122.702 16.333 121.207 17.8244 121.207 19.663Z"
          fill="#F5F5F5"
        />
        <path
          d="M161.865 19.663C161.865 21.5017 163.36 22.993 165.203 22.993C167.046 22.993 168.541 21.5017 168.541 19.663C168.541 17.8244 167.046 16.333 165.203 16.333C163.36 16.333 161.865 17.8244 161.865 19.663Z"
          fill="#F5F5F5"
        />
        <path
          d="M221.936 117.49C221.936 119.329 223.43 120.82 225.273 120.82C227.116 120.82 228.611 119.329 228.611 117.49C228.611 115.652 227.116 114.16 225.273 114.16C223.43 114.16 221.936 115.652 221.936 117.49Z"
          fill="#F5F5F5"
        />
        <path
          d="M41.5391 178.595C41.5391 180.433 43.0339 181.925 44.8769 181.925C46.7199 181.925 48.2147 180.433 48.2147 178.595C48.2147 176.756 46.7199 175.265 44.8769 175.265C43.0339 175.265 41.5391 176.756 41.5391 178.595Z"
          fill="#F5F5F5"
        />
        <path
          d="M71.3643 83.0666C71.3643 78.235 75.3062 74.3125 80.1389 74.3125C84.9716 74.3125 88.9135 78.2452 88.9135 83.0666C88.9135 87.8879 84.9716 91.8206 80.1389 91.8206C75.3062 91.8206 71.3643 87.8879 71.3643 83.0666ZM72.7567 83.0666C72.7567 87.1321 76.0741 90.4416 80.1491 90.4416C84.2241 90.4416 87.5415 87.1321 87.5415 83.0666C87.5415 79.0011 84.2241 75.6915 80.1491 75.6915C76.0741 75.6915 72.7567 79.0011 72.7567 83.0666Z"
          fill="#F5F5F5"
        />
        <path
          d="M189.243 92.1779C189.243 87.3463 193.185 83.4238 198.018 83.4238C202.85 83.4238 206.792 87.3565 206.792 92.1779C206.792 96.9993 202.85 100.932 198.018 100.932C193.185 100.932 189.243 96.9993 189.243 92.1779ZM190.636 92.1779C190.636 96.2434 193.953 99.553 198.028 99.553C202.103 99.553 205.42 96.2434 205.42 92.1779C205.42 88.1124 202.103 84.8028 198.028 84.8028C193.953 84.8028 190.636 88.1124 190.636 92.1779Z"
          fill="#F5F5F5"
        />
        <path
          d="M204.038 44.2189C204.038 39.3873 207.98 35.4648 212.813 35.4648C217.645 35.4648 221.587 39.3975 221.587 44.2189C221.587 49.0403 217.645 52.973 212.813 52.973C207.98 52.973 204.038 49.0403 204.038 44.2189ZM205.431 44.2189C205.431 48.2844 208.748 51.594 212.823 51.594C216.898 51.594 220.215 48.2844 220.215 44.2189C220.215 40.1534 216.898 36.8438 212.823 36.8438C208.748 36.8438 205.431 40.1534 205.431 44.2189Z"
          fill="#F5F5F5"
        />
        <path
          d="M58.8633 25.9455C58.8633 21.1139 62.8052 17.1914 67.6379 17.1914C72.4706 17.1914 76.4125 21.1241 76.4125 25.9455C76.4125 30.7669 72.4706 34.6995 67.6379 34.6995C62.8052 34.6995 58.8633 30.7669 58.8633 25.9455ZM60.2557 25.9455C60.2557 30.011 63.5731 33.3205 67.6481 33.3205C71.7232 33.3205 75.0405 30.011 75.0405 25.9455C75.0405 21.88 71.7232 18.5704 67.6481 18.5704C63.5731 18.5704 60.2557 21.88 60.2557 25.9455Z"
          fill="#F5F5F5"
        />
        <path
          opacity="0.15"
          d="M122.057 223.785C183.999 223.785 234.212 173.689 234.212 111.893C234.212 50.0961 183.999 0 122.057 0C60.1151 0 9.90137 50.0961 9.90137 111.893C9.90137 173.689 60.1151 223.785 122.057 223.785Z"
          fill="#fac71a"
        />
        <path
          d="M152.036 114.671C161.794 116.908 166.483 118.645 173.824 129.718C181.165 140.791 188.353 153.6 190.309 159.463C192.264 165.326 193.554 176.859 189.582 179.801C185.609 182.743 152.036 148.819 152.036 148.819V114.661V114.671Z"
          fill="#fac71a"
        />
        <path
          d="M111.889 110.902L108.818 110.932C108.818 110.932 85.2174 117.991 82.7397 119.012C82.7397 119.012 77.907 134.6 81.3574 157.338C82.0741 162.078 83.3847 174.989 83.3233 184.908C83.2414 198.31 82.2687 216.267 82.2687 216.267C82.2687 216.267 98.6609 225.665 131.405 223.724C157.114 222.202 164.005 215.358 164.005 215.358C164.005 215.358 163.022 180.771 162.653 161.874C162.172 137.052 157.513 119.084 152.036 114.661L134.2 110.115L111.889 110.892V110.902Z"
          fill="#fac71a"
        />
        <path
          d="M143.343 64.2303C141.643 61.1659 139.462 59.9503 138.07 59.4702C136.821 53.2699 128.66 48.8877 123.623 47.2738C116.773 45.0776 109.749 46.6711 103.289 49.3167C100.995 50.2565 98.5481 51.0737 96.0806 50.7877C95.0055 50.6651 93.7768 50.1339 93.5925 49.0716C92.753 49.521 92.3025 50.471 92.067 51.3903C91.4936 53.5457 91.7393 55.8951 92.7325 57.8869C91.77 58.0197 91.0226 58.7961 90.4902 59.6132C88.8622 62.1465 88.7087 65.5174 89.9475 68.2549C92.5687 74.0672 98.886 74.0978 104.2 75.7526C109.463 77.3972 114.736 79.0315 119.998 80.6761C124.421 82.0551 128.834 83.4341 133.258 84.8029L133.073 91.7387V101.688C133.073 101.688 135.571 100.973 136.728 98.6235C137.885 96.2741 139.8 93.0156 141.848 84.6395C145.042 71.6156 144.715 66.6717 143.353 64.2303H143.343Z"
          fill="#263238"
        />
        <path
          d="M142.247 93.3733C137.148 96.019 134.691 92.0046 134.691 92.0046L134.466 111.127C137.261 113.037 143.773 122.394 133.053 125.162C121.933 128.032 110.456 119.339 108.92 113.129L108.818 107.061C108.818 107.061 107.087 107.112 104.333 106.744C99.7461 106.152 95.8246 103.088 94.3297 96.9894C92.5277 89.6245 92.753 75.4668 94.6062 65.7219C95.3229 61.9629 96.5004 60.9005 98.6812 59.7565C104.098 56.9066 120.162 55.977 124.636 62.5349C124.125 66.58 125.404 71.003 129.152 72.2492C129.438 78.6232 130.421 81.6876 132.971 82.6785C135.039 83.4854 135.95 80.3086 137.312 78.7968C138.694 77.3055 143.926 75.855 146.322 80.6151C148.749 85.4569 146.977 90.932 142.257 93.3733H142.247Z"
          fill="#C8856A"
        />
        <path
          d="M108.817 107.071C114.807 106.877 120.111 105.999 123.612 104.416C127.114 102.832 130.114 97.9701 131.015 93.21C131.117 97.0201 129.623 103.823 124.339 106.775C119.056 109.727 112.811 110.044 108.889 110.238L108.817 107.082V107.071Z"
          fill="#AF6152"
        />
        <path
          d="M113.497 71.3194L118.586 73.4134C119.139 71.9629 118.442 70.3183 117.04 69.7361C115.637 69.1538 114.05 69.8689 113.507 71.3194H113.497Z"
          fill="#263238"
        />
        <path
          d="M96.5819 72.3302L101.896 70.7776C101.517 69.2862 100.022 68.418 98.5477 68.847C97.0836 69.276 96.1928 70.8389 96.5716 72.3302H96.5819Z"
          fill="#263238"
        />
        <path
          d="M101.835 76.8664C101.835 77.9492 100.657 78.8072 99.2341 78.797C97.8109 78.7868 96.6539 77.8981 96.6641 76.8153C96.6641 75.7325 97.8416 77.4282 99.275 77.4384C100.698 77.4486 101.845 75.7734 101.835 76.8562V76.8664Z"
          fill="#263238"
        />
        <path
          d="M118.411 77.2541C118.411 78.3368 117.233 79.1949 115.81 79.1847C114.387 79.1745 113.23 78.2858 113.24 77.203C113.24 76.1202 114.418 77.8159 115.851 77.8261C117.274 77.8363 118.421 76.1611 118.411 77.2439V77.2541Z"
          fill="#263238"
        />
        <path
          d="M109.053 73.4131L107.589 87.6934L99.9814 85.1192C102.531 80.0323 105.551 76.1302 109.053 73.4131Z"
          fill="#AF6152"
        />
        <path
          d="M115.381 91.4833C114.982 91.3301 114.531 91.3301 114.112 91.3199C113.692 91.3096 113.282 91.3199 112.862 91.3811C112.228 91.4731 111.603 91.5854 110.989 91.7489C109.77 92.0655 108.368 92.5558 107.477 93.4752C107.221 93.7408 107.702 94.2515 107.999 94.1289C108.634 93.8838 109.228 93.5671 109.852 93.3117C110.467 93.0564 111.112 92.8827 111.757 92.7193C112.371 92.5661 113.006 92.4843 113.63 92.3822C113.968 92.3311 114.296 92.2698 114.634 92.2085C114.941 92.1575 115.289 92.1473 115.545 91.943C115.709 91.8102 115.524 91.5344 115.381 91.4833Z"
          fill="#AF6152"
        />
        <path
          d="M84.3576 119.461C72.5523 123.833 66.0917 135.723 76.6069 151.75C85.6989 165.602 103.647 181.506 109.494 185.296C119.221 191.588 124.053 191.353 131.999 182.803C141.306 172.793 155.466 156.296 159.479 151.066C163.503 145.836 169.37 145.192 171.879 140.177C174.387 135.172 175.073 132.015 177.408 128.001C179.281 124.794 184.8 119.727 185.926 115.488C186.53 114.579 187.001 113.659 187.298 112.74C187.8 111.157 186.95 110.033 185.865 110.135C186.346 109.522 186.541 109.175 186.541 107.929C186.541 106.468 184.923 106.233 184.134 106.621C182.568 107.388 179.691 109.196 179.691 109.196C178.257 109.196 177.725 109.247 177.162 109.369C176.343 109.563 175.626 110.043 174.93 110.513C172.749 111.994 170.558 113.476 168.377 114.967C167.077 115.856 165.766 116.744 164.599 117.807C161.998 120.176 160.421 123.22 158.763 126.254C157.145 129.217 154.892 132.24 152.681 134.835C148.411 139.85 119.733 160.137 119.733 160.137C119.733 160.137 109.32 148.941 102.132 138.022C92.6101 123.558 89.5487 121.402 84.3576 119.492V119.461Z"
          fill="#C8856A"
        />
        <path
          d="M174.326 121.719C177.039 119.277 179.722 116.805 182.609 114.568C183.439 114.037 184.319 113.046 185.384 113.087C186.746 114.139 186.111 116.274 185.445 117.48C183.469 120.585 181.053 123.302 178.708 126.121C180.162 124.037 183.777 119.359 185.015 117.255C185.64 116.131 185.978 114.548 185.21 113.588C185.21 113.588 185.169 113.588 185.118 113.588C181.176 115.712 177.971 119.114 174.306 121.708L174.326 121.719Z"
          fill="#AF6152"
        />
        <path
          d="M170.772 120.799C175.175 116.519 180.1 112.413 185.854 110.125C180.499 113.026 175.585 117.081 170.772 120.799Z"
          fill="#AF6152"
        />
        <path
          d="M179.691 109.175C175.739 111.933 171.94 114.834 167.947 117.541C171.469 114.262 175.36 111.31 179.691 109.175Z"
          fill="#AF6152"
        />
        <path
          d="M106.381 142.536C101.815 136.265 94.3916 120.432 84.9105 118.256C77.4465 120.207 70.6377 127.133 70.6377 135.621C70.6377 144.109 72.8083 148.716 79.7092 157.695C86.6102 166.674 100.688 180.28 109.484 185.275C109.555 173.344 113.559 164.182 119.692 160.075C119.692 160.075 110.937 148.798 106.381 142.526V142.536Z"
          fill="#fac71a"
        />
        <path
          opacity="0.2"
          d="M119.692 160.095C115.084 163.303 109.76 169.084 109.197 175.377C107.108 165.856 117.552 157.327 117.552 157.327L119.692 160.095Z"
          fill="black"
        />
        <path
          d="M184.063 157.286C186.203 159.247 188.036 161.842 189.408 164.876C190.565 167.44 191.097 170.269 191.077 173.078C191.066 175.264 191.107 179.228 188.814 180.351C188.169 180.668 187.432 180.79 186.715 180.811C185.507 180.842 184.34 180.954 183.08 180.862C181.749 180.76 180.428 180.596 179.107 180.392C176.466 179.984 173.968 179.044 171.623 177.767C166.8 175.142 163.667 171.219 160.115 167.174C154.422 160.688 148.627 154.212 143.364 147.368C139.34 142.138 133.473 141.494 130.965 136.479C128.456 131.473 127.77 128.317 125.436 124.303C123.562 121.095 118.044 116.029 116.917 111.79C116.313 110.88 115.842 109.961 115.545 109.042C115.044 107.459 115.893 106.335 116.979 106.437C116.497 105.824 116.303 105.477 116.303 104.231C116.303 102.77 117.921 102.535 118.709 102.923C120.276 103.689 123.153 105.497 123.153 105.497C124.586 105.497 125.118 105.548 125.682 105.671C126.501 105.865 127.217 106.345 127.914 106.815C130.095 108.296 132.286 109.777 134.466 111.269C135.767 112.157 137.077 113.046 138.245 114.108C140.845 116.478 142.422 119.522 144.081 122.556C145.698 125.518 147.951 128.542 150.163 131.136C154.504 136.234 160.862 140.166 166.186 144.14C169.667 146.734 173.435 149.503 176.968 152.036C179.65 153.966 181.831 155.264 184.043 157.286H184.063Z"
          fill="#C8856A"
        />
        <path
          d="M159.08 165.979C153.316 159.534 147.644 152.792 142.125 146.142C140.128 144.048 137.538 142.669 135.122 141.055C129.879 137.909 128.968 131.321 126.316 126.315C123.89 121.075 118.627 117.592 116.712 111.851C116.067 110.83 114.613 108.674 115.422 107.091C115.699 106.519 116.364 106.11 117.02 106.192L116.794 106.601C116.508 106.253 116.221 105.794 116.139 105.304C115.944 104.374 115.985 103.066 117.05 102.678C117.531 102.474 118.043 102.443 118.545 102.586C120.275 103.342 121.709 104.323 123.296 105.283L123.153 105.242C123.992 105.252 124.842 105.232 125.702 105.426C126.583 105.63 127.32 106.121 128.006 106.591L132.142 109.41C134.845 111.33 137.855 112.985 140.006 115.661C143.18 119.625 144.869 124.405 148.094 128.297C151.012 132.403 155.077 135.386 158.998 138.491C161.63 140.545 164.292 142.567 166.964 144.569C172.299 148.584 177.684 152.537 183.111 156.439C174.92 150.667 166.739 144.865 158.814 138.737C156.173 136.683 153.541 134.63 151.084 132.342C148.719 129.942 146.753 127.224 144.93 124.405C143.18 121.545 141.818 118.47 139.678 115.937C137.589 113.353 134.61 111.729 131.876 109.808L127.729 106.999C126.368 105.916 124.842 105.681 123.153 105.763C121.596 104.956 119.989 103.782 118.392 103.097C117.654 102.872 116.682 103.209 116.579 103.976C116.497 104.772 116.579 105.661 117.173 106.305L117.552 106.774L116.958 106.713C114.347 107.009 116.293 110.462 117.132 111.718C117.951 114.476 119.866 116.795 121.637 119.032C123.47 121.279 125.436 123.496 126.644 126.172C128.6 130.044 129.654 134.385 132.091 137.97C134.835 141.403 139.34 142.73 142.309 145.979C147.818 152.669 153.347 159.493 159.06 166.01L159.08 165.979Z"
          fill="#AF6152"
        />
        <path
          d="M128.538 118.031C124.883 115.436 121.668 112.045 117.726 109.91C117.675 109.91 117.634 109.91 117.634 109.91C117.695 109.879 117.624 109.93 117.583 109.981C116.856 111.023 117.245 112.464 117.828 113.577C119.057 115.661 122.702 120.401 124.135 122.443C121.791 119.634 119.364 116.907 117.398 113.802C116.733 112.596 116.098 110.462 117.46 109.409C118.525 109.369 119.405 110.359 120.235 110.891C123.122 113.128 125.804 115.6 128.518 118.041L128.538 118.031Z"
          fill="#AF6152"
        />
        <path
          d="M132.091 117.112C127.269 113.394 122.365 109.338 117.01 106.438C122.764 108.726 127.699 112.832 132.091 117.112Z"
          fill="#AF6152"
        />
        <path
          d="M123.173 105.487C127.494 107.622 131.395 110.584 134.917 113.853C130.924 111.146 127.135 108.245 123.173 105.487Z"
          fill="#AF6152"
        />
        <path
          d="M47.2742 58.3896C48.5938 46.6159 40.0966 36.0042 28.2952 34.6877C16.4939 33.3712 5.85728 41.8485 4.53772 53.6222C3.21815 65.3959 11.7153 76.0076 23.5167 77.3241C35.318 78.6406 45.9546 70.1633 47.2742 58.3896Z"
          fill="white"
        />
        <path
          d="M0.921571 62.8003C4.68944 76.58 18.9316 84.6906 32.7334 80.9315C46.5455 77.1725 54.6751 62.974 50.9072 49.1942C47.1394 35.4247 32.9075 27.3039 19.0954 31.063C5.29352 34.822 -2.84629 49.0308 0.921571 62.8003ZM5.17066 61.646C2.04784 50.2157 8.80542 38.4176 20.2524 35.3021C31.7198 32.1866 43.5353 38.9284 46.6581 50.3485C49.781 61.789 43.0336 73.5667 31.5764 76.6924C20.1193 79.8079 8.30372 73.0764 5.17066 61.646Z"
          fill="#fac71a"
        />
        <path
          d="M48.0809 68.0303L53.0979 78.2859C53.958 80.0326 52.2072 81.9224 50.3847 81.2073L39.7568 76.9989L48.0809 68.0303Z"
          fill="#fac71a"
        />
        <path
          d="M7.33035 70.6666L11.2109 74.5381L44.4855 41.3414L40.6049 37.4699L7.33035 70.6666Z"
          fill="#fac71a"
        />
        <path
          d="M13.8761 139.306L11.3145 144.009L34.8218 156.753L37.3834 152.05L13.8761 139.306Z"
          fill="#E0E0E0"
        />
        <path
          d="M15.5799 158.5L20.2939 161.056L33.068 137.603L28.354 135.048L15.5799 158.5Z"
          fill="#E0E0E0"
        />
        <path
          d="M213.259 156.528L216.28 162.074L244.025 147.033L241.004 141.486L213.259 156.528Z"
          fill="#E0E0E0"
        />
        <path
          d="M223.855 136.456L218.295 139.47L233.372 167.149L238.931 164.135L223.855 136.456Z"
          fill="#E0E0E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_598_4311">
          <rect width="244" height="224" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
