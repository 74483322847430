import { alert } from "../../utils";
import { http } from "../../services";
import { IEntity } from "./types";

import Compressor from "compressorjs";

export const addProduct = async (
  productData: IEntity.Products,
  setLoading: any,
  navigate: any
) => {
  try {
    setLoading(true);
    const formData = new FormData();

    await Promise.all(
      productData.images.map(async (file: File, index: number) => {
        const maxFileSize = 400000;
        const defaultQuality = 0.6;
        let quality = defaultQuality;
        if (file.size < maxFileSize) {
          quality = 1 - (file.size / maxFileSize) * (1 - defaultQuality);
        }

        const compressedFile = await new Promise<Blob>((resolve, reject) => {
          new Compressor(file, {
            quality: quality,
            success(result: Blob) {
              resolve(result);
            },
            error(error: Error) {
              reject(error);
            },
          });
        });

        formData.append("images", compressedFile, `image-${index}.jpg`);
      })
    );

    Object.entries(productData)
      .filter(([key]) => key !== "images")
      .forEach(([key, value]) => {
        formData.append(key, value);
      });

    await http.post(`/product/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    alert.success("E'lon muvaffaqiyatli joylandi!");
    navigate("/");
  } catch (error: any) {
    alert.error(
      "Elon joylashda xatolik!, Barcha Ma'lumotlarni tekshiring yoki birozdan so'ng urinib ko'ring"
    );
  } finally {
    setLoading(false);
  }
};

export const editProduct = async (
  productData: IEntity.Products,
  setLoading: any,
  naivgate: any
) => {
  try {
    setLoading(true);
    await http.put(`/product/`, { ...productData });
    alert.success("E'lon muvaffaqiyatli o'zgartirildi!");
    naivgate("/");
  } catch (error: any) {
    alert.error("Elon o'zgartishda xatolik!");
  } finally {
    setLoading(false);
  }
};

export const editImageProduct = async (images: any, productId: string) => {
  try {
    const formData = new FormData();
    images.forEach((file: any, index: number) => {
      formData.append("images", file);
    });
    formData.append("productId", productId);

    await http.post(`/product/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error: any) {
    alert.error("Rasm o'zgartirishda xatolik!");
  }
};

export const deleteImageProduct = async (imageId: any, productId: string) => {
  try {
    await http.delete(`/product/${productId}`, { data: { imageId } });
  } catch (error: any) {
    alert.error("Rasm o'zgartirishda xatolik!");
  }
};

export const getAllProducts = async () => {
  try {
    const response = await http.get(`/product/all/`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProductsPagination = async (scroll: string) => {
  try {
    const response = await http.get(scroll);
    return response.data;
  } catch (error: any) {
    alert.error(error);
  }
};

export const getSingleProduct = async (productId: string) => {
  try {
    const response = await http.get(`/product/one_product/?id=${productId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getSimilarProducts = async (productId: string) => {
  try {
    const response = await http.get(`/product/retrieve/?id=${productId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUsersProduct = async () => {
  try {
    const response = await http.get(`/product`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const filterProducts = async (query: string) => {
  try {
    const response = await http.get(`/product/search/?${query}`);
    return response.data;
  } catch (error: any) {
    alert.error("Xatolik" + error);
  }
};

export const deleteProduct = async (productId: string) => {
  try {
    await http.delete(`/product/?id=${productId}`);
    alert.success("Eloningiz o'chirildi!");
    window.location.reload();
  } catch (error) {
    alert.error("Xatolik!");
  }
};

export const addLike = async (id: string) => {
  try {
    await http.post(`/product/product_like/`, { id });
  } catch (error: any) {
    alert.warning("Avval hisobingizga kiring");
  }
};

export const getUsersLikes = async (setLoading: any) => {
  try {
    setLoading(true);
    const response = await http.get(`/product/product_like`);
    return response.data;
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export const addBucket = async (product_id: string) => {
  try {
    await http.post(`/user/bucket/`, {
      product_id,
    });
  } catch (error: any) {
    alert.warning("Avval hisobingizga kiring");
  }
};

export const getUsersBuckets = async (setLoading: any) => {
  try {
    setLoading(true);
    const response = await http.get(`/user/bucket/`);
    return response.data;
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export const deleteUsersBucket = async (productId: string) => {
  try {
    await http.delete(`/user/bucket/?product_id=${productId}`);
  } catch (error) {
    alert.error("Xatolik!" + error);
  }
};

export const downloadApp = async () => {
  try {
    const response = await http.get(`/product/apk`);
    return response.data.file;
  } catch (error: any) {
    alert.error("Xatolik!");
  }
};
