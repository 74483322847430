import { useState } from "react";
import Style from "./productCarousel.module.scss";

export default function ProductCarousel({ images }: { images: File[] }) {
  const [chosenImage, setChosenImage] = useState(images[0].name);

  return (
    <div className={Style.productCarousel}>
      <div className={Style.miniProducts}>
        {images?.map((image, index) => (
          <img
            key={index}
            src={URL.createObjectURL(image)}
            onClick={() => setChosenImage(image.name)}
            alt={`product_img_${index}`}
            className={chosenImage === image.name ? Style.optionImage : ""}
          />
        ))}
      </div>
      <div className={Style.mainProduct}>
        <div>
          <img
            src={
              chosenImage
                ? URL.createObjectURL(
                    //@ts-ignore
                    images.find((image) => image.name === chosenImage)
                  )
                : ""
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
