import React, { useState } from "react";
import { Button, InputBase, PasswordInput, Text, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IMaskInput } from "react-imask";
import { IconPhoneCalling, IconLock } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";

import { signIn, signInWithFacebook } from "../../../modules/auth/service";

import IphoneImage from "../../../assets/images/Group 30.png";
import { Container, GoogleLogin } from "../../../components";
import Style from "./login.module.scss";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const clientId = process.env.REACT_APP_CLIENTID as string;

  const navigate = useNavigate();

  const form = useForm({
    initialValues: { password: "", phone: "" },

    validate: {
      password: (value) => {
        if (value.length < 6) {
          return "Parol 6 ta belgidan kam bo'lmasligi kerek!";
        }

        return null;
      },
      phone: (value) => {
        if (value.length === 0) {
          return "Telefon raqamingizni kiriting!";
        }
        if (value.length < 18) {
          return "Telefon raqamingizni togri kiriting!";
        }
        return null;
      },
    },
  });

  const login = () => {
    if (form.isValid()) {
      signIn(form.values.phone, form.values.password, setLoading);
    }
  };

  const [visible, { toggle }] = useDisclosure(false);

  React.useEffect(() => {
    function start() {
      gapi.client.init({
        clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className={Style.loginForm}>
      <Container>
        <div className={Style.formWrapper}>
          <div className={Style.imageContainer}>
            <div>
              <img src={IphoneImage} alt="Iphone Image" />
            </div>
          </div>

          <div className={Style.formContainer}>
            <div className={Style.formBox}>
              <div className={Style.formController}>
                <div className={Style.pathButtons}>
                  <button type="button" className={Style.activePath}>
                    Kirish
                  </button>
                  <button
                    onClick={() => navigate("/auth/register")}
                    type="button"
                  >
                    Ro'yxatdan o'tish
                  </button>
                </div>

                <div>
                  <div className={Style.socialButtons}>
                    <button>
                      <GoogleLogin />
                    </button>

                    <button onClick={() => signInWithFacebook()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="28"
                        viewBox="0 0 29 28"
                        fill="none"
                      >
                        <path
                          d="M26.7496 14C26.7496 10.7511 25.459 7.63527 23.1617 5.33795C20.8644 3.04063 17.7485 1.75001 14.4996 1.75001C11.415 1.74716 8.44279 2.90814 6.17678 5.00105C3.91078 7.09395 2.51776 9.96471 2.27599 13.0399C2.03422 16.115 2.9615 19.1682 4.8726 21.5895C6.7837 24.0108 9.53792 25.622 12.5851 26.1013V17.5403H9.47712V14H12.5869V11.3015C12.5869 8.23201 14.4156 6.5345 17.2139 6.5345C18.5544 6.5345 19.9561 6.77426 19.9561 6.77426V9.7895H18.4109C16.8884 9.7895 16.4159 10.7345 16.4159 11.7023V14H19.8126L19.2701 17.5403H16.4141V26.1013C19.2955 25.645 21.9195 24.1755 23.8141 21.9572C25.7088 19.7389 26.7497 16.9173 26.7496 14Z"
                          fill="#1877F2"
                        />
                      </svg>
                      <p>Facebook</p>
                    </button>
                  </div>

                  <div className={Style.formOption}>
                    <div className={Style.border} />
                    <span>Telefon raqam bilan</span>
                    <div className={Style.border} />
                  </div>
                </div>
                <div>
                  <form onSubmit={form.onSubmit(console.log)}>
                    <InputBase
                      size="lg"
                      component={IMaskInput}
                      mask="+998(00) 000-00-00"
                      placeholder="Telefon raqam"
                      leftSection={<IconPhoneCalling size={20} />}
                      {...form.getInputProps("phone")}
                    />

                    <PasswordInput
                      size="lg"
                      placeholder="Parol"
                      radius={8}
                      visible={visible}
                      onVisibilityChange={toggle}
                      leftSection={<IconLock size={20} />}
                      {...form.getInputProps("password")}
                    />

                    <Box className={Style.checkers}>
                      <div>
                        <input type="checkbox" id="horns" name="horns" />
                        <label
                          htmlFor="horns"
                          style={{ fontFamily: "Almarai" }}
                        >
                          Eslab qolish
                        </label>
                      </div>
                      <Text
                        onClick={() => navigate("/auth/forget-password")}
                        c="#4285F4"
                        ff="Almarai"
                        style={{ cursor: "pointer" }}
                        className={Style.forgotText}
                      >
                        Parolingizni unutdingizmi?
                      </Text>
                    </Box>

                    <Button
                      onClick={() => login()}
                      className={Style.formSubmiter}
                      type="submit"
                      variant="filled"
                      color="#fac73a"
                      mt="sm"
                      loading={loading}
                    >
                      Kirish
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
