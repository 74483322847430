import { GoogleLogout } from "react-google-login";
import { clearSession } from "../../utils";

export default function Logout() {
  const clientId = process.env.REACT_APP_CLIENTID as string;

  const onSuccess = () => {
    // window.location.href("");
    // clearSession();
  };

  return (
    <div id="signInButton">
      <GoogleLogout
        clientId={clientId}
        buttonText="Hisobdan Chiqish"
        onLogoutSuccess={onSuccess}
        render={({ onClick }) => (
          <button
            onClick={onClick}
            style={{
              width: "100%",
              height: "36px",
              background: "none",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            Ha, chiqaman
          </button>
        )}
      />
    </div>
  );
}
