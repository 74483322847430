import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntity } from "../modules/auth";
import { getAccessToken } from "../utils";

interface UserState {
  userInfo: IEntity.User | null;
}

const initialState: UserState = {
  userInfo: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState["userInfo"]>) => {
      state.userInfo = action.payload;
    },
  },
});

const token = getAccessToken();
export const { setUser } = userSlice.actions;
export const selectUser = (state: { user: UserState }) => state.user.userInfo;
export const isAuthenticated = token ? true : false;

export default userSlice.reducer;
