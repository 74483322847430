import React, { FunctionComponent } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Flex,
  LoadingOverlay,
  NumberFormatter,
} from "@mantine/core";
import { Banner, Footer, Header, ShowProductCarousel } from "../../components";
import { IconPhoneCall, IconBrandTelegram } from "@tabler/icons-react";

import {
  IEntity,
  addBucket,
  addLike,
  getSingleProduct,
} from "../../modules/main";
import { alert } from "../../utils";

import { SimilarProducts } from "../../sections";
import Style from "./show-product.module.scss";

const ShowProduct: FunctionComponent = () => {
  const { productId } = useParams<{ productId: string }>();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<IEntity.SingleProduct>();
  const [isliked, setIsLiked] = useState(product?.liked_status);
  const [isCart, setIsCart] = useState(product?.isCart);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProducts();
  }, [productId]);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productData = await getSingleProduct(`${productId}`);
      setProduct(productData);
      setIsLiked(productData?.liked_status);
      setIsCart(productData?.isCart);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const addLikeToProduct = (id: string) => {
    setIsLiked(!isliked);
    addLike(id);
  };

  const addCart = (id: string) => {
    setIsCart(true);
    addBucket(id);
  };

  return (
    <div className={Style.showProductWrapper}>
      <Header />
      <Container size="85rem">
        {loading && (
          <LoadingOverlay
            visible={loading}
            zIndex={100}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "#fac71a", type: "bars" }}
          />
        )}

        <div className={Style.showContainer}>
          <div className={Style.choosenProductImages}>
            {product?.images && <ShowProductCarousel images={product.images} />}
          </div>

          <div className={Style.choosenProductInfo}>
            <div className={Style.productFirstInfo}>
              <div className={Style.infoDate}>
                <p>{product?.time.slice(0, 10)} da joylandi</p>

                <div className={Style.infoAction}>
                  <svg
                    cursor={"pointer"}
                    onClick={() => {
                      if (product) addLikeToProduct(product?.id.toString());
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 32 32"
                    fill={isliked ? "#fac71a" : "none"}
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.18929 5.22738C4.59151 3.85959 6.49309 3.09121 8.47583 3.09121C10.4586 3.09121 12.3601 3.85959 13.7624 5.22738L15.9533 7.36328L18.1441 5.22738C18.8339 4.53054 19.659 3.97471 20.5713 3.59234C21.4836 3.20996 22.4647 3.00869 23.4576 3.00028C24.4504 2.99186 25.4351 3.17646 26.354 3.54331C27.273 3.91016 28.1078 4.45191 28.8099 5.13695C29.512 5.82199 30.0672 6.6366 30.4432 7.53325C30.8192 8.4299 31.0083 9.39063 30.9997 10.3594C30.9911 11.3281 30.7848 12.2855 30.3929 13.1757C30.001 14.0658 29.4314 14.8709 28.7172 15.5439L15.9533 28L3.18929 15.5439C1.78749 14.1757 1 12.3203 1 10.3856C1 8.45101 1.78749 6.59558 3.18929 5.22738V5.22738Z"
                      stroke={isliked ? "#fac71a" : "black"}
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className={Style.choosenProductName}>
                <p>{product?.phoneName}</p>
              </div>

              <div className={Style.choosenProductCost}>
                <p className={Style.productCost}>
                  <NumberFormatter thousandSeparator value={product?.cost} />
                  {product?.costType}
                </p>
              </div>

              <div className={Style.choosenUserInfo}>
                <Button
                  leftSection={<IconPhoneCall size={25} />}
                  variant="outline"
                  color="#fac73a"
                >
                  <a
                    style={{ textDecoration: "none", color: "#fac71a" }}
                    href={`tel:${product?.phoneNumber}`}
                  >
                    Telefon qilish
                  </a>
                </Button>

                <Button
                  leftSection={<IconBrandTelegram size={25} />}
                  onClick={() => {
                    window.location.href = `https://t.me/${product?.telegram}`;
                  }}
                  variant="filled"
                  color="#fac73a"
                >
                  Yozish
                </Button>
              </div>
            </div>

            <div className={Style.productSecondInfo}>
              <div className={Style.phoneInfo}>
                <div className={Style.firstInfoDetail}>
                  <Button color="#FAC73A" variant="outline">
                    {product?.phoneMemory}
                  </Button>

                  <Button color="#FAC73A" variant="outline">
                    {product?.phoneMarka}
                  </Button>
                </div>

                <div className={Style.secondInfoDetail}>
                  <Button color="#FAC73A" variant="outline">
                    {product?.isNew ? "NEW" : "B/U"}
                  </Button>
                </div>
              </div>

              <div className={Style.aboutProduct}>
                <p>Maxsulot haqida:</p>
              </div>

              <div className={Style.productInfos}>
                <p>{product?.comment}</p>
              </div>

              <div className={Style.location}>
                <Flex gap={2}>
                  <p className={Style.city}>Manzil: </p>
                  <span> {product?.adress}</span>
                </Flex>
              </div>

              <div className={Style.line}></div>

              <div className={Style.detail}>
                <p>ID: {product?.id}</p>
                <p>Ko’rishlar soni: {product?.views}</p>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    isCart
                      ? navigate("/cart")
                      : addCart(product?.id.toString() as string);
                  }}
                  style={{
                    border: "solid 1px #FAC73A",
                    borderRadius: "8px",
                    background: `${isCart ? "#fac71a" : "none"}`,
                  }}
                  className={Style.productCard}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M1 0.015625C0.447715 0.015625 0 0.46334 0 1.01562C0 1.56791 0.447715 2.01562 1 2.01562V0.015625ZM3.29254 1.01562L4.25776 0.75421C4.13971 0.318307 3.74414 0.015625 3.29254 0.015625V1.01562ZM7.01791 14.7709L6.05268 15.0323C6.18295 15.5133 6.64747 15.8249 7.14194 15.7631L7.01791 14.7709ZM18.4806 13.338L18.6046 14.3303C19.0293 14.2772 19.3731 13.9597 19.4599 13.5406L18.4806 13.338ZM20.2 5.02757L21.1793 5.23017C21.2402 4.93566 21.1653 4.62936 20.9754 4.39614C20.7855 4.16293 20.5007 4.02757 20.2 4.02757V5.02757ZM4.3791 5.02757L3.41388 5.28898L4.3791 5.02757ZM1 2.01562H3.29254V0.015625H1V2.01562ZM7.14194 15.7631L18.6046 14.3303L18.3566 12.3457L6.89387 13.7786L7.14194 15.7631ZM19.4599 13.5406L21.1793 5.23017L19.2207 4.82496L17.5013 13.1354L19.4599 13.5406ZM2.32731 1.27704L3.41388 5.28898L5.34433 4.76615L4.25776 0.75421L2.32731 1.27704ZM3.41388 5.28898L6.05268 15.0323L7.98314 14.5094L5.34433 4.76615L3.41388 5.28898ZM20.2 4.02757H4.3791V6.02757H20.2V4.02757ZM9.6 18.5229C9.6 18.799 9.37614 19.0229 9.1 19.0229V21.0229C10.4807 21.0229 11.6 19.9036 11.6 18.5229H9.6ZM9.1 19.0229C8.82386 19.0229 8.6 18.799 8.6 18.5229H6.6C6.6 19.9036 7.71929 21.0229 9.1 21.0229V19.0229ZM8.6 18.5229C8.6 18.2468 8.82386 18.0229 9.1 18.0229V16.0229C7.71929 16.0229 6.6 17.1422 6.6 18.5229H8.6ZM9.1 18.0229C9.37614 18.0229 9.6 18.2468 9.6 18.5229H11.6C11.6 17.1422 10.4807 16.0229 9.1 16.0229V18.0229ZM17.6 18.5229C17.6 18.799 17.3761 19.0229 17.1 19.0229V21.0229C18.4807 21.0229 19.6 19.9036 19.6 18.5229H17.6ZM17.1 19.0229C16.8239 19.0229 16.6 18.799 16.6 18.5229H14.6C14.6 19.9036 15.7193 21.0229 17.1 21.0229V19.0229ZM16.6 18.5229C16.6 18.2468 16.8239 18.0229 17.1 18.0229V16.0229C15.7193 16.0229 14.6 17.1422 14.6 18.5229H16.6ZM17.1 18.0229C17.3761 18.0229 17.6 18.2468 17.6 18.5229H19.6C19.6 17.1422 18.4807 16.0229 17.1 16.0229V18.0229Z"
                      fill={isCart ? "#fff" : "black"}
                    />
                  </svg>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <Box mt={40}>
          <SimilarProducts id={productId as string} />
        </Box>
        <Banner />
      </Container>
      <Footer />
    </div>
  );
};

export default ShowProduct;
