import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntity } from "../modules/auth";

interface ForgotPasswordState {
  forgotPasswordInfo: IEntity.ForgotPassword | null;
}

const initialState: ForgotPasswordState = {
  forgotPasswordInfo: null,
};

const forgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState,
  reducers: {
    setforgotPasswordInfo: (
      state,
      action: PayloadAction<ForgotPasswordState["forgotPasswordInfo"]>
    ) => {
      state.forgotPasswordInfo = action.payload;
    },
  },
});

export const { setforgotPasswordInfo } = forgotPasswordSlice.actions;
export const selectforgotPasswordInfo = (state: {
  forgotPassword: ForgotPasswordState;
}) => state.forgotPassword.forgotPasswordInfo;

export default forgotPasswordSlice.reducer;
