import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  NumberFormatter,
} from "@mantine/core";

import { NotReport } from "../not-report";
import { IEntity, deleteProduct, getUsersProduct } from "../../../modules/main";

import { useDisclosure } from "@mantine/hooks";

import { alert } from "../../../utils";
import Style from "./my-report.module.scss";

export default function MyReport() {
  const url = process.env.REACT_APP_BASE_URL as string;
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersProduct();
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={100}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "#fac71a", type: "bars" }}
        />
      )}

      {products?.length === 0 ? (
        <NotReport />
      ) : (
        <div className={Style.reportWrapper}>
          {products.map((product) => (
            <div
              key={product.id}
              className={Style.reportBox}
              onClick={() => navigate(`/product/${product.id}`)}
            >
              <div className={Style.reportInfos}>
                <div className={Style.reportImage}>
                  <img
                    src={`${url}/${product.images.image}`}
                    alt="product-img"
                  />
                </div>

                <div className={Style.reportedinfo}>
                  <div className={Style.phoneInfo}>
                    <p>{product.phoneName}</p>
                    <p>
                      <NumberFormatter
                        thousandSeparator
                        value={product?.cost}
                      />
                      {product.costType}
                    </p>
                  </div>

                  <div className={Style.userLocation}>
                    <p>Telefon savdosi - {product.phoneMarka}</p>
                    <Flex gap={5} align={"center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M14 24.5C14 24.5 22.5 17.1957 22.5 11.7174C22.5 7.17905 18.6944 3.5 14 3.5C9.30558 3.5 5.5 7.17905 5.5 11.7174C5.5 17.1957 14 24.5 14 24.5Z"
                          stroke="black"
                          stroke-width="2"
                        />
                        <path
                          d="M16.7156 11.3752C16.7156 12.8249 15.5 14.0002 14.0003 14.0002C12.5007 14.0002 11.2851 12.8249 11.2851 11.3752C11.2851 9.92542 12.5007 8.75017 14.0003 8.75017C15.5 8.75017 16.7156 9.92542 16.7156 11.3752Z"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                      <p>{product.adress}</p>
                    </Flex>

                    <Flex gap={5} align={"center"}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M8.57192 9.66491H19.8811M8.06213 3.50011V5.51128M8.06213 5.51128L19.9368 5.51106M8.06213 5.51128C6.0946 5.51128 4.4998 7.10522 4.4999 9.07167L4.50045 20.9397C4.50054 22.906 6.09545 24.5 8.06284 24.5H19.9375C21.905 24.5 23.5 22.9058 23.4999 20.9394L23.4993 9.07133C23.4993 7.10502 21.9042 5.51106 19.9368 5.51106M19.9368 3.5V5.51106M11.6254 20.3462V13.2253L9.25047 15.0055M18.1565 20.3462V13.2253L15.7815 15.0055"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p>{product.time.slice(0, 10)} </p>
                    </Flex>
                  </div>

                  <div className={Style.reportResult}>
                    <div className={Style.productDetail}>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            d="M14 21.4983C22.0096 21.6293 24.5 14.1301 24.5 14.1301C24.5 14.1301 22.1106 6.5 14 6.5C5.88942 6.5 3.5 14.1301 3.5 14.1301C3.5 14.1301 5.99038 21.3673 14 21.4983Z"
                            stroke="black"
                            stroke-width="2"
                          />
                          <path
                            d="M11.3203 13.9751C11.3203 15.4248 12.4956 16.6001 13.9453 16.6001C15.395 16.6001 16.5703 15.4248 16.5703 13.9751C16.5703 12.5253 15.395 11.3501 13.9453 11.3501C12.4956 11.3501 11.3203 12.5253 11.3203 13.9751Z"
                            stroke="black"
                            stroke-width="2"
                          />
                        </svg>
                        <p>5421</p>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.0325 7.01462C6.01406 6.08452 7.34516 5.56202 8.73308 5.56202C10.121 5.56202 11.4521 6.08452 12.4337 7.01462L13.9673 8.46703L15.5009 7.01462C15.9837 6.54077 16.5613 6.16281 17.1999 5.90279C17.8385 5.64277 18.5253 5.50591 19.2203 5.50019C19.9153 5.49446 20.6045 5.61999 21.2478 5.86945C21.8911 6.11891 22.4755 6.4873 22.9669 6.95312C23.4584 7.41895 23.847 7.97289 24.1102 8.58261C24.3734 9.19233 24.5058 9.84563 24.4998 10.5044C24.4938 11.1631 24.3494 11.8142 24.0751 12.4195C23.8007 13.0248 23.402 13.5722 22.9021 14.0299L13.9673 22.5L5.0325 14.0299C4.05124 13.0995 3.5 11.8378 3.5 10.5222C3.5 9.20669 4.05124 7.945 5.0325 7.01462V7.01462Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p>255</p>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            d="M24.2558 21.9265C24.2558 21.9265 22.8842 23.2587 22.548 23.6493C22.0005 24.2271 21.3553 24.5 20.5095 24.5C20.4282 24.5 20.3415 24.5 20.2602 24.4946C18.65 24.393 17.1536 23.7724 16.0314 23.2427C12.9628 21.7767 10.2683 19.6954 8.02919 17.0577C6.18045 14.8587 4.94434 12.8256 4.12569 10.6427C3.62149 9.31045 3.43716 8.27248 3.51848 7.29338C3.5727 6.66739 3.81667 6.14841 4.26665 5.70433L6.11539 3.87987C6.38105 3.63376 6.66297 3.5 6.93946 3.5C7.28102 3.5 7.55752 3.70331 7.73101 3.87452C7.73643 3.87987 7.74185 3.88522 7.74727 3.89057C8.07799 4.19554 8.39243 4.51121 8.72315 4.84828C8.89121 5.01949 9.0647 5.1907 9.23819 5.36726L10.7183 6.8279C11.293 7.39503 11.293 7.91936 10.7183 8.4865C10.561 8.64166 10.4092 8.79681 10.252 8.94662C9.79661 9.40675 10.1544 9.0537 9.68269 9.47102C9.67184 9.48172 9.661 9.48708 9.65558 9.49778C9.18933 9.9579 9.27607 10.4073 9.37366 10.7123C9.37908 10.7283 9.3845 10.7444 9.38992 10.7605C9.77485 11.6807 10.317 12.5475 11.1411 13.5801L11.1465 13.5854C12.6428 15.4045 14.2205 16.8224 15.9608 17.9085C16.1831 18.0476 16.4108 18.1599 16.6277 18.2669C16.8228 18.3633 17.0072 18.4542 17.1644 18.5505C17.1861 18.5612 17.2078 18.5773 17.2295 18.588C17.4138 18.6789 17.5873 18.7217 17.7662 18.7217C18.2162 18.7217 18.4981 18.4435 18.5903 18.3526L19.6529 17.3038C19.8373 17.1219 20.13 16.9025 20.4716 16.9025C20.8077 16.9025 21.0842 17.1112 21.2523 17.2931C21.2577 17.2985 21.2577 17.2985 21.2631 17.3038L24.2504 20.2518C24.8088 20.7976 24.2558 21.9265 24.2558 21.9265Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p>36</p>
                      </div>
                    </div>
                    <div className={Style.reportButton}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/editProduct/${product.id}`);
                        }}
                        color="#fac73a"
                        variant="outline"
                      >
                        Tahrirlash
                      </Button>

                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          // deleteProduct(product.id.toString());
                          open();
                        }}
                        color="#fac73a"
                      >
                        Olib tashlash
                      </Button>

                      <Modal
                        zIndex={9999}
                        opened={opened}
                        onClose={close}
                        title="Rostdan ham e'loningizni o'chirmoqchimisiz?"
                        transitionProps={{
                          transition: "pop-bottom-left",
                          duration: 100,
                        }}
                      >
                        <Button
                          ff={"Poppins"}
                          fz={15}
                          fw={400}
                          bg="#fac73a"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteProduct(product.id.toString());
                          }}
                        >
                          Olib tashlash
                        </Button>
                      </Modal>
                    </div>
                  </div>

                  <p className={Style.line} />
                </div>
              </div>
              <p className={Style.reportId}>ID: {product.id}</p>
            </div>
          ))}
          <p className={Style.totalReport}>Jami e’lonlar: {products.length}</p>
        </div>
      )}
    </>
  );
}
