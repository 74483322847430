import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import EmptyLogo from "../../assets/images/emptyLogo";
import Style from "./chat.module.scss";
import { Footer, Header } from "../../components";

export default function Empty() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className={Style.emptyWrapper}>
        <div>
          <EmptyLogo />
          <p className={Style.emptyTitle}>Chat tez orada!!!</p>
          <p className={Style.emptyText}>
            Telefon egasi bilan telefon nomer orqali bog'laning, chat ishlab
            chiqish jarayonida⌚!!!
          </p>
          <Button color="#fac73a" onClick={() => navigate("/")}>
            Bosh sahifa
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
