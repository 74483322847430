import { Button, InputBase, Flex, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IMaskInput } from "react-imask";
import { IconPhoneCalling } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../components";

import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../modules";

import Style from "./forget-password.module.scss";
import IphoneImage from "../../../assets/images/Group 30.png";
import { useState } from "react";

export default function Forget() {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: { phone: "" },

    validate: {
      phone: (value) => {
        if (value.length === 0) {
          return "Telefon raqamingizni kiriting!";
        }
        if (value.length < 18) {
          return "Telefon raqamingizni togri kiriting!";
        }
        return null;
      },
    },
  });

  const handleSubmit = () => {
    if (form.isValid()) {
      forgotPassword(form.values.phone, dispatch, navigate);
    }
  };

  return (
    <div className={Style.forgetForm}>
      <Container>
        <div className={Style.formWrapper}>
          <div className={Style.imageContainer}>
            <div>
              <img src={IphoneImage} alt="Iphone Image" />
            </div>
          </div>

          <div className={Style.formContainer}>
            <div className={Style.formBox}>
              <div className={Style.formController}>
                <div className={Style.pathButtons}>
                  <p>Parolni tiklash</p>
                </div>
                <div>
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <InputBase
                      size="lg"
                      component={IMaskInput}
                      mask="+998(00) 000-00-00"
                      placeholder="Telefon raqam"
                      leftSection={<IconPhoneCalling size={20} />}
                      {...form.getInputProps("phone")}
                    />

                    <Button
                      className={Style.formSubmiter}
                      type="submit"
                      variant="filled"
                      color="#fac73a"
                      mt="sm"
                      // loading={loading}
                    >
                      Yuborish
                    </Button>

                    <Flex
                      mt={20}
                      style={{ justifyContent: "center" }}
                      align="center"
                      gap={5}
                    >
                      <Text
                        className={Style.changePathText}
                        fs="normal"
                        c="#8291A6"
                        fw={400}
                        lts="0.56px"
                      >
                        Ortga qaytish!
                      </Text>

                      <Text
                        onClick={() => navigate("/auth/login")}
                        style={{ cursor: "pointer" }}
                        className={Style.changePathText}
                        fs="normal"
                        c="#065AD8"
                        fw={400}
                        lts="0.56px"
                      >
                        Kirish
                      </Text>
                    </Flex>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
