import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import EmptyLogo from "../../assets/images/emptyLogo";
import Style from "./empty.module.scss";

export default function Empty() {
  const navigate = useNavigate();

  return (
    <div>
      <>
        <div className={Style.emptyWrapper}>
          <div>
            <EmptyLogo />
            <p className={Style.emptyTitle}>Savatda hozircha mahsulot yoʻq</p>
            <p className={Style.emptyText}>
              Bosh sahifadagi to’plamlardan boshlang yoki kerakli <br />{" "}
              mahsulotni qidiruv orqali toping
            </p>
            <Button color="#fac73a" onClick={() => navigate("/")}>
              Bosh sahifa
            </Button>
          </div>
        </div>
      </>
    </div>
  );
}
