import axios from "axios";
import { getAccessToken } from "../utils";
import { config } from "../config";

export { AxiosError } from "axios";

const http = axios.create({ baseURL: config.api.baseURL });

const token = getAccessToken();

http.interceptors.request.use(
  (request) => {
    // @ts-ignore
    request.headers = {
      ...request.headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };

    return request;
  },

  (error) => Promise.reject(error)
);

export default http;
