import { Menu, Button, Modal, Flex, MenuItem } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import { useSelector } from "react-redux";
import { selectUser } from "../../redux";
import { clearSession } from "../../utils";
import { useNavigate } from "react-router-dom";

import Style from "./profile.module.scss";
import { GoogleLougut } from "../google-auth";

export default function Profile() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [opened, { open, close }] = useDisclosure(false);

  const handleEnter = () => {
    if (!user) navigate("/auth/login");
  };

  return (
    <Menu
      zIndex={1111}
      trigger="hover"
      openDelay={100}
      closeDelay={200}
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <Button
          w={"auto"}
          onClick={() => handleEnter()}
          variant="default"
          leftSection={<IconUser size={22} />}
          className={Style.profileButton}
        >
          <p>{user ? user.first_name : "Kirish"}</p>
        </Button>
      </Menu.Target>

      {user ? (
        <Menu.Dropdown className={Style.menu}>
          <Menu.Label
            style={{
              cursor: "pointer",
              color: "#fac73a",
              fontFamily: "Poppins",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Hisobingiz
          </Menu.Label>

          <div className={Style.border}>
            <div className={Style.line}></div>
          </div>

          <Menu.Item
            onClick={() => navigate("/myProfile/reports")}
            className={Style.items}
            leftSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M18.1542 3.5C18.6466 3.5 19.0456 3.90295 19.0456 4.4V7.7813L17.2628 9.5813V5.3H4.78285V19.7H17.2628V17.2178L19.0456 15.4178V20.6C19.0456 21.0971 18.6466 21.5 18.1542 21.5H3.89142C3.39911 21.5 3 21.0971 3 20.6V4.4C3 3.90295 3.39911 3.5 3.89142 3.5H18.1542ZM19.7393 9.62685L21 10.8996L14.0663 17.9L12.8038 17.8981L12.8057 16.6272L19.7393 9.62685ZM11.9142 12.5V14.3H7.45712V12.5H11.9142ZM14.5885 8.9V10.7H7.45712V8.9H14.5885Z"
                  fill="#889098"
                />
              </svg>
            }
          >
            <Flex justify={"space-between"}>
              <p>E’lonlarim</p>
              {/* <p className={Style.badgeCount}>1</p> */}
            </Flex>
          </Menu.Item>

          <Menu.Item
            onClick={() => navigate("/myProfile/wishes")}
            className={Style.items}
            leftSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.31357 6.33643C5.15491 5.51576 6.29585 5.05473 7.4855 5.05473C8.67515 5.05473 9.81609 5.51576 10.6574 6.33643L11.972 7.61797L13.2865 6.33643C13.7003 5.91832 14.1954 5.58483 14.7428 5.3554C15.2901 5.12598 15.8788 5.00522 16.4746 5.00017C17.0703 4.99511 17.661 5.10588 18.2124 5.32598C18.7638 5.54609 19.2647 5.87114 19.6859 6.28217C20.1072 6.69319 20.4403 7.18196 20.6659 7.71995C20.8915 8.25794 21.005 8.83438 20.9998 9.41564C20.9947 9.99689 20.8709 10.5713 20.6358 11.1054C20.4006 11.6395 20.0588 12.1225 19.6303 12.5263L11.972 20L4.31357 12.5263C3.47249 11.7054 3 10.5922 3 9.43139C3 8.27061 3.47249 7.15735 4.31357 6.33643V6.33643Z"
                  stroke="#889098"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            <Flex justify={"space-between"}>
              <p>Istaklarim</p>
              {/* <p className={Style.badgeCount}>3</p> */}
            </Flex>
          </Menu.Item>

          <Menu.Item
            onClick={() => navigate("/myProfile/settings")}
            leftSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.6763 2.81627C11.2488 1.06124 8.75121 1.06124 8.3237 2.81627C8.25987 3.07999 8.13468 3.32492 7.95831 3.53112C7.78194 3.73732 7.55938 3.89897 7.30874 4.00291C7.0581 4.10684 6.78646 4.15014 6.51592 4.12927C6.24538 4.10839 5.9836 4.02394 5.75187 3.88279C4.20832 2.94227 2.44201 4.70855 3.38254 6.25207C3.99006 7.24884 3.45117 8.54936 2.31713 8.82499C0.560955 9.25137 0.560955 11.75 2.31713 12.1753C2.58093 12.2392 2.8259 12.3645 3.03211 12.541C3.23831 12.7175 3.39991 12.9402 3.50375 13.191C3.6076 13.4418 3.65074 13.7135 3.62968 13.9841C3.60862 14.2547 3.52394 14.5165 3.38254 14.7482C2.44201 16.2917 4.20832 18.058 5.75187 17.1175C5.98356 16.9761 6.24536 16.8914 6.51597 16.8704C6.78658 16.8493 7.05834 16.8924 7.30912 16.9963C7.5599 17.1001 7.7826 17.2617 7.95911 17.4679C8.13561 17.6741 8.26091 17.9191 8.32482 18.1829C8.75121 19.939 11.2499 19.939 11.6752 18.1829C11.7393 17.9192 11.8647 17.6744 12.0413 17.4684C12.2178 17.2623 12.4405 17.1008 12.6912 16.997C12.9419 16.8932 13.2135 16.85 13.4841 16.8709C13.7546 16.8919 14.0164 16.9764 14.2481 17.1175C15.7917 18.058 17.558 16.2917 16.6175 14.7482C16.4763 14.5165 16.3918 14.2547 16.3709 13.9842C16.35 13.7136 16.3932 13.442 16.497 13.1913C16.6008 12.9406 16.7623 12.7179 16.9683 12.5414C17.1744 12.3648 17.4192 12.2394 17.6829 12.1753C19.439 11.7489 19.439 9.25025 17.6829 8.82499C17.4191 8.76108 17.1741 8.63578 16.9679 8.45928C16.7617 8.28278 16.6001 8.06007 16.4962 7.8093C16.3924 7.55853 16.3493 7.28677 16.3703 7.01617C16.3914 6.74556 16.4761 6.48376 16.6175 6.25207C17.558 4.70855 15.7917 2.94227 14.2481 3.88279C14.0164 4.02418 13.7546 4.10886 13.484 4.12992C13.2134 4.15098 12.9417 4.10784 12.6909 4.004C12.4401 3.90016 12.2174 3.73856 12.0409 3.53236C11.8644 3.32616 11.7391 3.08119 11.6752 2.8174L11.6763 2.81627Z"
                  stroke="#889098"
                  stroke-width="2"
                />
                <path
                  d="M12 10.5C12 11.6046 11.1046 12.5 10 12.5C8.89543 12.5 8 11.6046 8 10.5C8 9.39543 8.89543 8.5 10 8.5C11.1046 8.5 12 9.39543 12 10.5Z"
                  stroke="#889098"
                  stroke-width="2"
                />
              </svg>
            }
          >
            <p>Sozlamalar</p>
          </Menu.Item>

          <Menu.Item
            color="red"
            onClick={open}
            leftSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M9.35294 16.7001V18.8001C9.35294 19.3571 9.57605 19.8912 9.97319 20.285C10.3703 20.6788 10.909 20.9001 11.4706 20.9001H18.8824C19.444 20.9001 19.9826 20.6788 20.3798 20.285C20.7769 19.8912 21 19.3571 21 18.8001L21 6.2001C21 5.64314 20.7769 5.109 20.3798 4.71517C19.9826 4.32135 19.444 4.1001 18.8824 4.1001L11.4706 4.1001C10.909 4.1001 10.3703 4.32135 9.97319 4.71517C9.57605 5.109 9.35294 5.64314 9.35294 6.2001V8.3001M15.7059 12.5001L3 12.5001M3 12.5001L6.17647 15.6501M3 12.5001L6.17647 9.3501"
                  stroke="red"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            <span className={Style.logout}>Chiqish</span>
          </Menu.Item>
          {/* 
          <MenuItem>
            <GoogleLougut />
          </MenuItem> */}

          <Menu.Divider />
        </Menu.Dropdown>
      ) : null}

      <Modal
        zIndex={9999}
        opened={opened}
        onClose={close}
        title="Rostdan ham hisobingizdan chiqmoqchimisz?"
        transitionProps={{ transition: "pop-bottom-left", duration: 200 }}
      >
        <Button
          ff={"Poppins"}
          fz={16}
          fw={400}
          bg="#fac73a"
          onClick={() => {
            clearSession();
            window.location.reload();
          }}
        >
          <GoogleLougut />
        </Button>
      </Modal>
    </Menu>
  );
}
