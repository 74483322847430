import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Container, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useSelector } from "react-redux";
import { selectUser } from "../../redux/user-slice";

import { clearSession } from "../../utils";

import Style from "./menu-profile.module.scss";
import { BottomNavigation } from "../../components/bottom-navigation";
import { GoogleLougut } from "../../components";

export default function MenuProfile() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (windowWidth > 745) {
      navigate("/");
    }
  }, [windowWidth]);

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <div className={Style.menuWrapper}>
      <div className={Style.userInfo}>
        <Container size={"85rem"}>
          <h2 className={Style.title}>Mening Hisobim</h2>
          <div className={Style.infos}>
            <div className={Style.profileImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="26"
                viewBox="0 0 21 26"
                fill="none"
              >
                <path
                  d="M0 26C0 23.3731 1.10625 20.8538 3.07538 18.9963C5.04451 17.1388 7.71523 16.0952 10.5 16.0952C13.2848 16.0952 15.9555 17.1388 17.9246 18.9963C19.8938 20.8538 21 23.3731 21 26H18.375C18.375 24.0298 17.5453 22.1403 16.0685 20.7472C14.5916 19.3541 12.5886 18.5714 10.5 18.5714C8.41142 18.5714 6.40838 19.3541 4.93153 20.7472C3.45469 22.1403 2.625 24.0298 2.625 26H0ZM10.5 14.8571C8.94247 14.8571 7.41992 14.4215 6.12488 13.6052C4.82985 12.7889 3.82049 11.6288 3.22445 10.2714C2.62841 8.91397 2.47246 7.42033 2.77632 5.97933C3.08017 4.53833 3.8302 3.21469 4.93153 2.17578C6.03287 1.13688 7.43606 0.429374 8.96366 0.142741C10.4913 -0.143892 12.0747 0.0032187 13.5136 0.56547C14.9526 1.12772 16.1825 2.07986 17.0478 3.30148C17.9131 4.5231 18.375 5.95934 18.375 7.42857C18.375 9.39875 17.5453 11.2882 16.0685 12.6814C14.5916 14.0745 12.5886 14.8571 10.5 14.8571ZM10.5 12.381C11.5384 12.381 12.5534 12.0905 13.4167 11.5463C14.2801 11.0022 14.953 10.2287 15.3504 9.32377C15.7477 8.41884 15.8517 7.42308 15.6491 6.46241C15.4466 5.50175 14.9465 4.61932 14.2123 3.92671C13.4781 3.23411 12.5426 2.76244 11.5242 2.57135C10.5058 2.38026 9.45022 2.47834 8.49091 2.85317C7.5316 3.22801 6.71166 3.86276 6.13478 4.67718C5.55791 5.49159 5.25 6.44909 5.25 7.42857C5.25 8.74203 5.80312 10.0017 6.78769 10.9304C7.77225 11.8592 9.10761 12.381 10.5 12.381Z"
                  fill="#fac73a"
                />
              </svg>
            </div>

            <div className={Style.mainInfo}>
              <p>{user?.first_name}</p>
              <p>+{user?.phone_number}</p>
            </div>
          </div>
        </Container>
      </div>

      <div
        className={Style.userOptions}
        onClick={() => navigate("/myProfile/reports")}
      >
        <Container size={"85rem"}>
          <div className={Style.option}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M13.4447 3.09009C13.8003 3.09009 14 3.38102 14 3.74V6.09214L12.8009 7.39214V4.30009H3.78764V14.7001H12.8009V12.9074L14 11.6074V15.26C14 15.619 13.7056 15.91 13.35 15.91C12.9944 15.91 3.58556 15.91 3.23 15.91C2.87444 15.91 2.59003 15.7091 2.59003 15.3501V3.74C2.59003 3.38102 2.78827 3.09009 3.14383 3.09009H13.4447ZM14.5896 7.42503L15.5 8.34426L10.4924 13.4001L9.58055 13.3987L9.5819 12.4809L14.5896 7.42503ZM8.93809 9.50009V10.5H5.71906V9.50009H8.93809ZM10.8695 6.90009V8.11H5.71906V6.90009H10.8695Z"
                fill="black"
              />
            </svg>
            <p>E’lonlarim tarixi</p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 15.5L13 9L5 2.5"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Container>

        <div className={Style.border} />
      </div>

      <div
        className={Style.userOptions}
        onClick={() => navigate("/myProfile/wishes")}
      >
        <Container size={"85rem"}>
          <div className={Style.option}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.02167 4.06914C3.67604 3.4126 4.56344 3.04378 5.48872 3.04378C6.414 3.04378 7.3014 3.4126 7.95577 4.06914L8.97819 5.09438L10.0006 4.06914C10.3225 3.73466 10.7075 3.46786 11.1333 3.28432C11.559 3.10078 12.0169 3.00417 12.4802 3.00013C12.9435 2.99609 13.403 3.0847 13.8319 3.26079C14.2607 3.43688 14.6503 3.69691 14.978 4.02573C15.3056 4.35455 15.5647 4.74557 15.7402 5.17596C15.9156 5.60635 16.0039 6.0675 15.9999 6.53251C15.9958 6.99751 15.8996 7.45705 15.7167 7.88432C15.5338 8.31159 15.268 8.69802 14.9347 9.02108L8.97819 15L3.02167 9.02108C2.36749 8.36434 2 7.47374 2 6.54511C2 5.61649 2.36749 4.72588 3.02167 4.06914V4.06914Z"
                stroke="black"
                stroke-width="1.2"
                stroke-linejoin="round"
              />
            </svg>

            <p>Istaklarim</p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 15.5L13 9L5 2.5"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Container>

        <div className={Style.border} />
      </div>

      <div
        className={Style.userOptions}
        onClick={() => navigate("/myProfile/cart")}
      >
        <Container size={"85rem"}>
          <div className={Style.option}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M2.5 1.9C2.16863 1.9 1.9 2.16863 1.9 2.5C1.9 2.83137 2.16863 3.1 2.5 3.1V1.9ZM4.05224 2.5L4.63177 2.34462C4.56145 2.08235 4.32377 1.9 4.05224 1.9V2.5ZM6.57463 11.9079L5.9951 12.0633C6.07275 12.3529 6.3523 12.5407 6.64979 12.5031L6.57463 11.9079ZM14.3358 10.9279L14.411 11.5232C14.6659 11.491 14.8721 11.3 14.9236 11.0483L14.3358 10.9279ZM15.5 5.24396L16.0878 5.36436C16.124 5.18778 16.0788 5.0043 15.9649 4.86464C15.8509 4.72499 15.6802 4.64396 15.5 4.64396V5.24396ZM2.5 3.1H4.05224V1.9H2.5V3.1ZM6.64979 12.5031L14.411 11.5232L14.2607 10.3326L6.49946 11.3126L6.64979 12.5031ZM14.9236 11.0483L16.0878 5.36436L14.9122 5.12357L13.748 10.8075L14.9236 11.0483ZM3.47271 2.65538L4.2084 5.39934L5.36747 5.08858L4.63177 2.34462L3.47271 2.65538ZM4.2084 5.39934L5.9951 12.0633L7.15416 11.7525L5.36747 5.08858L4.2084 5.39934ZM15.5 4.64396H4.78794V5.84396H15.5V4.64396ZM8.4 14.4741C8.4 14.715 8.20823 14.9 7.98438 14.9V16.1C8.88235 16.1 9.6 15.3663 9.6 14.4741H8.4ZM7.98438 14.9C7.76052 14.9 7.56875 14.715 7.56875 14.4741H6.36875C6.36875 15.3663 7.0864 16.1 7.98438 16.1V14.9ZM7.56875 14.4741C7.56875 14.2331 7.76052 14.0482 7.98438 14.0482V12.8482C7.0864 12.8482 6.36875 13.5818 6.36875 14.4741H7.56875ZM7.98438 14.0482C8.20823 14.0482 8.4 14.2331 8.4 14.4741H9.6C9.6 13.5818 8.88235 12.8482 7.98438 12.8482V14.0482ZM13.8167 14.4741C13.8167 14.715 13.6249 14.9 13.401 14.9V16.1C14.299 16.1 15.0167 15.3663 15.0167 14.4741H13.8167ZM13.401 14.9C13.1772 14.9 12.9854 14.715 12.9854 14.4741H11.7854C11.7854 15.3663 12.5031 16.1 13.401 16.1V14.9ZM12.9854 14.4741C12.9854 14.2331 13.1772 14.0482 13.401 14.0482V12.8482C12.5031 12.8482 11.7854 13.5818 11.7854 14.4741H12.9854ZM13.401 14.0482C13.6249 14.0482 13.8167 14.2331 13.8167 14.4741H15.0167C15.0167 13.5818 14.299 12.8482 13.401 12.8482V14.0482Z"
                fill="black"
              />
            </svg>

            <p>Savatim</p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 15.5L13 9L5 2.5"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Container>
        <div className={Style.border} />
      </div>

      <div className={Style.userOptions} onClick={() => navigate("/chat")}>
        <Container size={"85rem"}>
          <div className={Style.option}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M3.74987 8.25C3.74987 4.93629 6.43594 2.25 9.74937 2.25C13.0628 2.25 15.7489 4.93629 15.7489 8.25C15.7489 9.11252 15.5669 9.93253 15.2392 10.6737L15.75 14.2494L12.6859 13.4834C11.8179 13.9715 10.8162 14.25 9.74937 14.25M2.25071 12C2.25071 12.5391 2.36444 13.0516 2.5692 13.5148L2.25 15.7496L4.16486 15.2708C4.70732 15.576 5.33336 15.75 6.00004 15.75C8.07074 15.75 9.74937 14.0711 9.74937 12C9.74937 9.92893 8.07074 8.25 6.00004 8.25C3.92934 8.25 2.25071 9.92893 2.25071 12Z"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Chatlarim</p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 15.5L13 9L5 2.5"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Container>
        <div className={Style.border} />
      </div>

      <div
        className={Style.userOptions}
        onClick={() => navigate("/myProfile/settings")}
      >
        <Container size={"85rem"}>
          <div className={Style.option}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.3038 3.02377C9.97128 1.65874 8.02872 1.65874 7.69621 3.02377C7.64657 3.22888 7.5492 3.41938 7.41202 3.57976C7.27485 3.74014 7.10174 3.86586 6.9068 3.9467C6.71185 4.02755 6.50058 4.06122 6.29016 4.04499C6.07974 4.02875 5.87613 3.96307 5.6959 3.85328C4.49536 3.12177 3.12157 4.49554 3.85309 5.69606C4.3256 6.47132 3.90647 7.48284 3.02444 7.69721C1.65852 8.02884 1.65852 9.97225 3.02444 10.303C3.22961 10.3527 3.42015 10.4502 3.58053 10.5874C3.74091 10.7247 3.8666 10.8979 3.94736 11.093C4.02813 11.288 4.06169 11.4994 4.04531 11.7099C4.02892 11.9203 3.96306 12.124 3.85309 12.3042C3.12157 13.5047 4.49536 14.8785 5.6959 14.1469C5.8761 14.037 6.07973 13.9711 6.2902 13.9547C6.50068 13.9383 6.71204 13.9719 6.90709 14.0527C7.10214 14.1334 7.27536 14.2591 7.41264 14.4195C7.54992 14.5799 7.64738 14.7704 7.69708 14.9756C8.02872 16.3415 9.97216 16.3415 10.3029 14.9756C10.3528 14.7705 10.4503 14.5801 10.5877 14.4198C10.725 14.2596 10.8982 14.134 11.0931 14.0532C11.2881 13.9725 11.4994 13.9389 11.7098 13.9552C11.9203 13.9715 12.1239 14.0372 12.3041 14.1469C13.5046 14.8785 14.8784 13.5047 14.1469 12.3042C14.0371 12.1239 13.9714 11.9203 13.9551 11.7099C13.9389 11.4995 13.9725 11.2882 14.0532 11.0932C14.1339 10.8982 14.2596 10.725 14.4198 10.5877C14.5801 10.4504 14.7705 10.3529 14.9756 10.303C16.3415 9.97137 16.3415 8.02797 14.9756 7.69721C14.7704 7.64751 14.5799 7.55005 14.4195 7.41277C14.2591 7.27549 14.1334 7.10228 14.0526 6.90723C13.9719 6.71219 13.9383 6.50082 13.9547 6.29035C13.9711 6.07988 14.0369 5.87626 14.1469 5.69606C14.8784 4.49554 13.5046 3.12177 12.3041 3.85328C12.1239 3.96325 11.9203 4.02911 11.7098 4.04549C11.4993 4.06187 11.288 4.02832 11.0929 3.94755C10.8979 3.86679 10.7246 3.7411 10.5874 3.58072C10.4501 3.42034 10.3526 3.22981 10.3029 3.02464L10.3038 3.02377Z"
                stroke="black"
                stroke-width="1.2"
              />
              <path
                d="M10.5556 9C10.5556 9.85911 9.85911 10.5556 9 10.5556C8.14089 10.5556 7.44444 9.85911 7.44444 9C7.44444 8.14089 8.14089 7.44444 9 7.44444C9.85911 7.44444 10.5556 8.14089 10.5556 9Z"
                stroke="black"
                stroke-width="1.2"
              />
            </svg>

            <p>Sozlamalar</p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5 15.5L13 9L5 2.5"
                stroke="black"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Container>

        <div className={Style.border} />
      </div>

      <div className={Style.loginWrapper}>
        <Button onClick={open} color="#fac73a">
          Chiqish
        </Button>
      </div>

      <Modal
        zIndex={9999}
        opened={opened}
        onClose={close}
        title="Rostdan ham hisobingizdan chiqmoqchimisz?"
        transitionProps={{ transition: "pop-bottom-left", duration: 200 }}
      >
        <Button
          ff={"Poppins"}
          fz={16}
          fw={400}
          bg="#fac73a"
          onClick={() => {
            clearSession();
            window.location.href = "/";
          }}
        >
          <GoogleLougut />
        </Button>
      </Modal>
      <BottomNavigation />
    </div>
  );
}
