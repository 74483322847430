import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchState {
  searchInfo: string | null;
}

const initialState: SearchState = {
  searchInfo: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<SearchState["searchInfo"]>) => {
      state.searchInfo = action.payload;
    },
  },
});

export const { setSearch } = searchSlice.actions;
export const selectSearch = (state: { search: SearchState }) =>
  state.search.searchInfo;

export default searchSlice.reducer;
