import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Skeleton } from "@mantine/core";

import { IEntity } from "../../modules/main/types";
import Style from "../../components/skeleton/skeleton.module.scss";

export default function SkeletonLoader() {
  const [likedProducts, setLikedProducts] = React.useState<string[]>([]);
  const [countProducts, setCountProducts] = useState(10);

  useEffect(() => {
    const updateSlides = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1350 && screenWidth > 892) {
        setCountProducts(8);
      } else if (screenWidth <= 892) {
        setCountProducts(6);
      } else {
        setCountProducts(10);
      }
    };

    updateSlides();

    const handleResize = () => {
      updateSlides();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={Style.productsContainer}>
      {Array.from({ length: countProducts }).map((_, index) => (
        <div key={index} className={Style.productBox}>
          <div className={Style.productImage}>
            <Skeleton height={"180px"} mt={6} width="100%" radius="15px" />
          </div>
          <div className={Style.productDescription}>
            <p className={Style.productName}>
              <div>
                <Skeleton height={"12px"} mt={6} width="100%" radius="15px" />
                <Skeleton height={"12px"} mt={6} width="100%" radius="15px" />
              </div>
            </p>
            <div className={Style.productPriceBox}>
              <Skeleton height={"30px"} mt={6} width="60%" radius="5px" />
            </div>
            <div className={Style.cardButtons}>
              <Skeleton height={"30px"} mt={6} width="40%" radius="5px" />
              <Flex
                style={{ justifyContent: "center" }}
                align={"center"}
                w={90}
                gap={10}
              >
                <Skeleton height={"30px"} mt={6} width="40%" radius="5px" />
                <Skeleton height={"30px"} mt={6} width="40%" radius="5px" />
              </Flex>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
