import { createRoot } from "react-dom/client";
import { Routes } from "./routes";
import { Provider } from "react-redux";
import { store } from "./redux";

import "./index.css";
import "../src/assets/fonts/fonts.css";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";

const rootElement = document.getElementById("root");

if (rootElement) {
  createRoot(rootElement).render(
    <Provider store={store}>
      <Routes />
      <Analytics />
    </Provider>
  );
}

reportWebVitals();
