import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./carousel.scss";

import BannerImage from "../../assets/images/banner01.png";
import BannerImage2 from "../../assets/images/banner02.png";
import BannerImage3 from "../../assets/images/banner03.png";
import BannerImage4 from "../../assets/images/banner04.png";
import BannerImage5 from "../../assets/images/banner05.png";

const swiperParams = {
  spaceBetween: 30,
  centeredSlides: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    clickable: true,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
};

export default function Banner() {
  return (
    <div className="swiper-container">
      <Swiper
        {...swiperParams}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={BannerImage} alt="banner-image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={BannerImage2} alt="banner-image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={BannerImage3} alt="banner-image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={BannerImage4} alt="banner-image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={BannerImage5} alt="banner-image" />
        </SwiperSlide>

        <div className="swiper-button-next"></div>

        <div className="swiper-button-prev"></div>
      </Swiper>
    </div>
  );
}
