import { useState } from "react";
import Style from "./carousel.module.scss";

export default function Carousel({
  images,
}: {
  images: { id: number; image: string; product: number }[];
}) {
  const [chosenImage, setChosenImage] = useState(images[0].image);
  const url = process.env.REACT_APP_BASE_URL as string;

  return (
    <div className={Style.productCarousel}>
      <div className={Style.miniProducts}>
        {images.map((image, index) => (
          <img
            key={index}
            src={`${url}/${image.image}`}
            onClick={() => setChosenImage(image.image)}
            alt={`product_img_${index}`}
            className={chosenImage === image.image ? Style.optionImage : ""}
          />
        ))}
      </div>
      <div className={Style.mainProduct}>
        <div>
          <img src={`${url}/${chosenImage}`} alt="product_img" />
        </div>
      </div>
    </div>
  );
}
