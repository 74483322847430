import React, { useEffect, useState } from "react";
import { BottomNavigation, Footer, Header } from "../../components";
import {
  Button,
  Container,
  FileButton,
  Flex,
  NumberInput,
  Select,
  TextInput,
  LoadingOverlay,
  InputBase,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import { useNavigate } from "react-router-dom";
import { CheckProduct } from "../check-product";
import { addProduct } from "../../modules";

import Style from "../add-product/add-product.module.scss";
import { Helmet } from "react-helmet";
import { IMaskInput } from "react-imask";

export default function ProductAdd() {
  const [file, setFile] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [file3, setFile3] = useState<File | null>(null);
  const [file4, setFile4] = useState<File | null>(null);
  const images = [file, file2, file3, file4];

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeButtonStatus, setActiveButtonStatus] = useState(false);
  const [activeButtonDoc, setActiveButtonDoc] = useState(false);
  const [checkImage, setCheckImage] = useState(true);

  const [checkProductStatus, setCheckProductStatus] = useState(true);

  const handleButtonClickStatus = (buttonName: any) => {
    setActiveButtonStatus(buttonName);
  };
  const handleButtonClickDoc = (buttonName: any) => {
    setActiveButtonDoc(buttonName);
  };

  const form = useForm({
    initialValues: {
      phoneName: "",
      phoneMarka: "",
      cost: "",
      costType: "",
      phoneMemory: "",
      phoneColor: "",
      isNew: activeButtonStatus,
      document: activeButtonDoc,
      comment: "",
      phoneNumber: "",
      adress: "",
      telegram: "",
      images: [] as (File | null)[],
    },

    validate: {
      phoneName: (value) => {
        if (value.length < 8) {
          return "8 ta belgidan kam bo'lmasligi kerek!";
        }
        if (value.length > 20) {
          return "25 ta belgidan kop bo'lmasligi kerek!";
        }
        return null;
      },

      telegram: (value) => {
        if (value.length === 0) {
          return "Telegram username kiritilsin!";
        }
        return null;
      },

      phoneColor: (value) => {
        if (value.length === 0) {
          return "Telefon rangini kiritish shart!";
        }

        return null;
      },

      images: (File) => {
        if (File === null) {
          return "Barcha rasmlarni kiriting";
        }
      },

      adress: (value) => {
        if (value.length === 0) {
          return "Manzil kiritilsin!";
        }

        return null;
      },

      phoneNumber: (value) => {
        if (value.length === 0) {
          return "Telefon raqami kiritilsin!";
        }

        return null;
      },

      phoneMarka: (value) => {
        if (!value) {
          return "Model tanlansin!";
        }
        return null;
      },

      cost: (value) => {
        if (value.length === 0) {
          return "Narx kiritilsin!";
        }
        if (value.length > 10) {
          return "Narx baland!";
        }

        return null;
      },

      costType: (value) => {
        if (!value) {
          return "Narx turi tanlansin!";
        }
        return null;
      },

      phoneMemory: (value) => {
        if (!value) {
          return "Xotira tanlansin!";
        }
        return null;
      },
    },
  });

  const handleSubmit = () => {
    if (form.isValid()) {
      form.values.images = [...images];
      form.values.document = activeButtonDoc;
      form.values.isNew = activeButtonStatus;
      addProduct(form.values, setLoading, navigate);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const handleCheck = () => {
    if (form.isValid()) {
      form.values.images = [...images];
      form.values.document = activeButtonDoc;
      form.values.isNew = activeButtonStatus;
      setCheckProductStatus(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return checkProductStatus ? (
    <div>
      <Header />
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={100}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "#fac71a", type: "bars" }}
        />
      )}
      <Container size={"84rem"}>
        <Helmet>
          <title>E'lon joylash</title>
          <meta
            name="description"
            content="Telmee E'lon joylang va soting barchasi juda oddiy.Telmee bilan barchasi juda oson. Telmee O'zbekistondagi birinchi va yagona platforma"
          />
          <link rel="canonical" href="https://telmee.uz/addProduct" />
        </Helmet>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <div className={Style.formBorder}>
            <Container size={"82rem"}>
              <p className={Style.aboutPhone}>Batafsil tasvirlab bering</p>
              <div className={Style.fromAboutPhone}>
                <Flex className={Style.phoneSomeInfo}>
                  <TextInput
                    label="Telefon turi"
                    placeholder="iPhone 15 Pro Max"
                    {...form.getInputProps("phoneName")}
                    className={Style.phoneType}
                    size="md"
                  />

                  <Select
                    label="Telefon modeli"
                    placeholder="iPhone"
                    data={[
                      "iPhone",
                      "Samsung",
                      "Redmi",
                      "Xiaomi",
                      "Huawei",
                      "Artel",
                      "Vivo",
                      "Lg",
                      "Honor",
                      "Lenovo",
                      "Infinix",
                      "Oppo",
                      "One Plus",
                      "Google",
                      "Motorola",
                      "Sony",
                      "Nokia",
                    ]}
                    className={Style.phoneBrand}
                    size="md"
                    {...form.getInputProps("phoneMarka")}
                  />
                </Flex>

                <Flex className={Style.productPrice}>
                  <NumberInput
                    label="Narx"
                    placeholder="1300"
                    {...form.getInputProps("cost")}
                    className={Style.price}
                    size="md"
                  />

                  <Select
                    label="Narx turi"
                    placeholder="usd"
                    data={["uzs", "usd", "y.e"]}
                    {...form.getInputProps("costType")}
                    className={Style.priceType}
                    size="md"
                  />
                </Flex>
              </div>
              <div className={Style.seccondInfo}>
                <Flex className={Style.phoneSomeInfo}>
                  <Select
                    label="Xotirasi"
                    placeholder="512GB"
                    data={[
                      "2GB",
                      "4GB",
                      "8GB",
                      "16GB",
                      "32GB",
                      "64GB",
                      "128GB",
                      "256GB",
                      "512GB",
                      "1TB",
                    ]}
                    className={Style.phoneType}
                    size="md"
                    {...form.getInputProps("phoneMemory")}
                  />

                  <TextInput
                    label="Telefon Rangi"
                    placeholder="Titanium Seriy"
                    {...form.getInputProps("phoneColor")}
                    className={Style.phoneBrand}
                    size="md"
                  />
                </Flex>

                <Flex className={Style.productPrice}>
                  <div className={Style.statusButton}>
                    <label>Xolati</label>
                    <br />
                    <div className={Style.infoButtonWrapper}>
                      <Button
                        onClick={() => handleButtonClickStatus(false)}
                        className={
                          activeButtonStatus === false ? Style.active : ""
                        }
                        size="17px"
                        variant="outline"
                        color="#889098"
                      >
                        B/U
                      </Button>

                      <Button
                        onClick={() => handleButtonClickStatus(true)}
                        className={
                          activeButtonStatus === true ? Style.active : ""
                        }
                        size="17px"
                        variant="outline"
                        color="#889098"
                      >
                        New
                      </Button>
                    </div>
                  </div>
                  <div className={Style.docButton}>
                    <label>Xujjatlari</label>
                    <br />
                    <div className={Style.infoButtonWrapper}>
                      <Button
                        onClick={() => handleButtonClickDoc(false)}
                        size="17px"
                        variant="outline"
                        color="#889098"
                        className={
                          activeButtonDoc === false ? Style.active : ""
                        }
                      >
                        Bor
                      </Button>
                      <Button
                        onClick={() => handleButtonClickDoc(true)}
                        size="17px"
                        variant="outline"
                        color="#889098"
                        className={activeButtonDoc === true ? Style.active : ""}
                      >
                        Yo'q
                      </Button>
                    </div>
                  </div>

                  <div className={Style.productMainDetails}>
                    <div className={Style.productStatus}></div>
                    <div className={Style.productDocument}></div>
                  </div>
                </Flex>
              </div>
            </Container>
          </div>

          <div className={Style.photoWrapper}>
            <Container size={"82rem"}>
              <p className={Style.aboutPhone}>Telefon suratlari</p>
              <div className={Style.productPhoto}>
                <div className={Style.productImageWrapper}>
                  <FileButton onChange={setFile} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button {...form.getInputProps("images")} {...props}>
                        {file && <img src={URL.createObjectURL(file)} alt="" />}
                        {file !== null ? (
                          ""
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M10.1999 8.67003V9.92003C10.6445 9.92003 11.0556 9.68389 11.2796 9.29987L10.1999 8.67003ZM12.5799 4.59003V3.34003C12.1353 3.34003 11.7242 3.57617 11.5002 3.96019L12.5799 4.59003ZM21.4199 4.59003L22.4996 3.96019C22.2756 3.57617 21.8645 3.34003 21.4199 3.34003V4.59003ZM23.7999 8.67003L22.7202 9.29987C22.9442 9.68389 23.3553 9.92003 23.7999 9.92003V8.67003ZM4.6499 26.01V12.07H2.1499V26.01H4.6499ZM6.7999 9.92003H10.1999V7.42003H6.7999V9.92003ZM11.2796 9.29987L13.6596 5.21987L11.5002 3.96019L9.12018 8.04019L11.2796 9.29987ZM12.5799 5.84003H21.4199V3.34003H12.5799V5.84003ZM20.3402 5.21987L22.7202 9.29987L24.8796 8.04019L22.4996 3.96019L20.3402 5.21987ZM23.7999 9.92003H27.1999V7.42003H23.7999V9.92003ZM29.3499 12.07V26.01H31.8499V12.07H29.3499ZM29.3499 26.01C29.3499 27.1974 28.3873 28.16 27.1999 28.16V30.66C29.768 30.66 31.8499 28.5782 31.8499 26.01H29.3499ZM27.1999 9.92003C28.3873 9.92003 29.3499 10.8826 29.3499 12.07H31.8499C31.8499 9.5019 29.768 7.42003 27.1999 7.42003V9.92003ZM4.6499 12.07C4.6499 10.8826 5.61249 9.92003 6.7999 9.92003V7.42003C4.23178 7.42003 2.1499 9.5019 2.1499 12.07H4.6499ZM6.7999 28.16C5.61249 28.16 4.6499 27.1974 4.6499 26.01H2.1499C2.1499 28.5781 4.23178 30.66 6.7999 30.66V28.16ZM20.8499 18.19C20.8499 20.3163 19.1262 22.04 16.9999 22.04V24.54C20.5069 24.54 23.3499 21.697 23.3499 18.19H20.8499ZM16.9999 22.04C14.8736 22.04 13.1499 20.3163 13.1499 18.19H10.6499C10.6499 21.697 13.4929 24.54 16.9999 24.54V22.04ZM13.1499 18.19C13.1499 16.0637 14.8736 14.34 16.9999 14.34V11.84C13.4929 11.84 10.6499 14.683 10.6499 18.19H13.1499ZM16.9999 14.34C19.1262 14.34 20.8499 16.0637 20.8499 18.19H23.3499C23.3499 14.683 20.5069 11.84 16.9999 11.84V14.34ZM27.1999 28.16H6.7999V30.66H27.1999V28.16Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </FileButton>

                  <FileButton onChange={setFile2} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button {...form.getInputProps("images")} {...props}>
                        {file2 && (
                          <img src={URL.createObjectURL(file2)} alt="" />
                        )}
                        {file2 !== null ? (
                          ""
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M10.1999 8.67003V9.92003C10.6445 9.92003 11.0556 9.68389 11.2796 9.29987L10.1999 8.67003ZM12.5799 4.59003V3.34003C12.1353 3.34003 11.7242 3.57617 11.5002 3.96019L12.5799 4.59003ZM21.4199 4.59003L22.4996 3.96019C22.2756 3.57617 21.8645 3.34003 21.4199 3.34003V4.59003ZM23.7999 8.67003L22.7202 9.29987C22.9442 9.68389 23.3553 9.92003 23.7999 9.92003V8.67003ZM4.6499 26.01V12.07H2.1499V26.01H4.6499ZM6.7999 9.92003H10.1999V7.42003H6.7999V9.92003ZM11.2796 9.29987L13.6596 5.21987L11.5002 3.96019L9.12018 8.04019L11.2796 9.29987ZM12.5799 5.84003H21.4199V3.34003H12.5799V5.84003ZM20.3402 5.21987L22.7202 9.29987L24.8796 8.04019L22.4996 3.96019L20.3402 5.21987ZM23.7999 9.92003H27.1999V7.42003H23.7999V9.92003ZM29.3499 12.07V26.01H31.8499V12.07H29.3499ZM29.3499 26.01C29.3499 27.1974 28.3873 28.16 27.1999 28.16V30.66C29.768 30.66 31.8499 28.5782 31.8499 26.01H29.3499ZM27.1999 9.92003C28.3873 9.92003 29.3499 10.8826 29.3499 12.07H31.8499C31.8499 9.5019 29.768 7.42003 27.1999 7.42003V9.92003ZM4.6499 12.07C4.6499 10.8826 5.61249 9.92003 6.7999 9.92003V7.42003C4.23178 7.42003 2.1499 9.5019 2.1499 12.07H4.6499ZM6.7999 28.16C5.61249 28.16 4.6499 27.1974 4.6499 26.01H2.1499C2.1499 28.5781 4.23178 30.66 6.7999 30.66V28.16ZM20.8499 18.19C20.8499 20.3163 19.1262 22.04 16.9999 22.04V24.54C20.5069 24.54 23.3499 21.697 23.3499 18.19H20.8499ZM16.9999 22.04C14.8736 22.04 13.1499 20.3163 13.1499 18.19H10.6499C10.6499 21.697 13.4929 24.54 16.9999 24.54V22.04ZM13.1499 18.19C13.1499 16.0637 14.8736 14.34 16.9999 14.34V11.84C13.4929 11.84 10.6499 14.683 10.6499 18.19H13.1499ZM16.9999 14.34C19.1262 14.34 20.8499 16.0637 20.8499 18.19H23.3499C23.3499 14.683 20.5069 11.84 16.9999 11.84V14.34ZM27.1999 28.16H6.7999V30.66H27.1999V28.16Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </FileButton>
                </div>
                <div className={Style.productImageWrapper}>
                  <FileButton onChange={setFile3} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button {...form.getInputProps("images")} {...props}>
                        {file3 && (
                          <img src={URL.createObjectURL(file3)} alt="" />
                        )}
                        {file3 !== null ? (
                          ""
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M10.1999 8.67003V9.92003C10.6445 9.92003 11.0556 9.68389 11.2796 9.29987L10.1999 8.67003ZM12.5799 4.59003V3.34003C12.1353 3.34003 11.7242 3.57617 11.5002 3.96019L12.5799 4.59003ZM21.4199 4.59003L22.4996 3.96019C22.2756 3.57617 21.8645 3.34003 21.4199 3.34003V4.59003ZM23.7999 8.67003L22.7202 9.29987C22.9442 9.68389 23.3553 9.92003 23.7999 9.92003V8.67003ZM4.6499 26.01V12.07H2.1499V26.01H4.6499ZM6.7999 9.92003H10.1999V7.42003H6.7999V9.92003ZM11.2796 9.29987L13.6596 5.21987L11.5002 3.96019L9.12018 8.04019L11.2796 9.29987ZM12.5799 5.84003H21.4199V3.34003H12.5799V5.84003ZM20.3402 5.21987L22.7202 9.29987L24.8796 8.04019L22.4996 3.96019L20.3402 5.21987ZM23.7999 9.92003H27.1999V7.42003H23.7999V9.92003ZM29.3499 12.07V26.01H31.8499V12.07H29.3499ZM29.3499 26.01C29.3499 27.1974 28.3873 28.16 27.1999 28.16V30.66C29.768 30.66 31.8499 28.5782 31.8499 26.01H29.3499ZM27.1999 9.92003C28.3873 9.92003 29.3499 10.8826 29.3499 12.07H31.8499C31.8499 9.5019 29.768 7.42003 27.1999 7.42003V9.92003ZM4.6499 12.07C4.6499 10.8826 5.61249 9.92003 6.7999 9.92003V7.42003C4.23178 7.42003 2.1499 9.5019 2.1499 12.07H4.6499ZM6.7999 28.16C5.61249 28.16 4.6499 27.1974 4.6499 26.01H2.1499C2.1499 28.5781 4.23178 30.66 6.7999 30.66V28.16ZM20.8499 18.19C20.8499 20.3163 19.1262 22.04 16.9999 22.04V24.54C20.5069 24.54 23.3499 21.697 23.3499 18.19H20.8499ZM16.9999 22.04C14.8736 22.04 13.1499 20.3163 13.1499 18.19H10.6499C10.6499 21.697 13.4929 24.54 16.9999 24.54V22.04ZM13.1499 18.19C13.1499 16.0637 14.8736 14.34 16.9999 14.34V11.84C13.4929 11.84 10.6499 14.683 10.6499 18.19H13.1499ZM16.9999 14.34C19.1262 14.34 20.8499 16.0637 20.8499 18.19H23.3499C23.3499 14.683 20.5069 11.84 16.9999 11.84V14.34ZM27.1999 28.16H6.7999V30.66H27.1999V28.16Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </FileButton>

                  <FileButton onChange={setFile4} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button {...form.getInputProps("images")} {...props}>
                        {file4 && (
                          <img src={URL.createObjectURL(file4)} alt="" />
                        )}
                        {file4 !== null ? (
                          ""
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <path
                              d="M10.1999 8.67003V9.92003C10.6445 9.92003 11.0556 9.68389 11.2796 9.29987L10.1999 8.67003ZM12.5799 4.59003V3.34003C12.1353 3.34003 11.7242 3.57617 11.5002 3.96019L12.5799 4.59003ZM21.4199 4.59003L22.4996 3.96019C22.2756 3.57617 21.8645 3.34003 21.4199 3.34003V4.59003ZM23.7999 8.67003L22.7202 9.29987C22.9442 9.68389 23.3553 9.92003 23.7999 9.92003V8.67003ZM4.6499 26.01V12.07H2.1499V26.01H4.6499ZM6.7999 9.92003H10.1999V7.42003H6.7999V9.92003ZM11.2796 9.29987L13.6596 5.21987L11.5002 3.96019L9.12018 8.04019L11.2796 9.29987ZM12.5799 5.84003H21.4199V3.34003H12.5799V5.84003ZM20.3402 5.21987L22.7202 9.29987L24.8796 8.04019L22.4996 3.96019L20.3402 5.21987ZM23.7999 9.92003H27.1999V7.42003H23.7999V9.92003ZM29.3499 12.07V26.01H31.8499V12.07H29.3499ZM29.3499 26.01C29.3499 27.1974 28.3873 28.16 27.1999 28.16V30.66C29.768 30.66 31.8499 28.5782 31.8499 26.01H29.3499ZM27.1999 9.92003C28.3873 9.92003 29.3499 10.8826 29.3499 12.07H31.8499C31.8499 9.5019 29.768 7.42003 27.1999 7.42003V9.92003ZM4.6499 12.07C4.6499 10.8826 5.61249 9.92003 6.7999 9.92003V7.42003C4.23178 7.42003 2.1499 9.5019 2.1499 12.07H4.6499ZM6.7999 28.16C5.61249 28.16 4.6499 27.1974 4.6499 26.01H2.1499C2.1499 28.5781 4.23178 30.66 6.7999 30.66V28.16ZM20.8499 18.19C20.8499 20.3163 19.1262 22.04 16.9999 22.04V24.54C20.5069 24.54 23.3499 21.697 23.3499 18.19H20.8499ZM16.9999 22.04C14.8736 22.04 13.1499 20.3163 13.1499 18.19H10.6499C10.6499 21.697 13.4929 24.54 16.9999 24.54V22.04ZM13.1499 18.19C13.1499 16.0637 14.8736 14.34 16.9999 14.34V11.84C13.4929 11.84 10.6499 14.683 10.6499 18.19H13.1499ZM16.9999 14.34C19.1262 14.34 20.8499 16.0637 20.8499 18.19H23.3499C23.3499 14.683 20.5069 11.84 16.9999 11.84V14.34ZM27.1999 28.16H6.7999V30.66H27.1999V28.16Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </Button>
                    )}
                  </FileButton>
                </div>
              </div>
              <p className={Style.errorPhoto}>Iltimos 4ta rasm tanlansin!</p>
            </Container>
          </div>

          <div className={Style.personalText}>
            <Container size={"82rem"}>
              <p className={Style.aboutPhone}>Fikr mulohaza qoldiring</p>
              <div>
                <textarea
                  name=""
                  className={Style.textInfo}
                  placeholder="Qo’shimcha ma'lumotlarini shu yerda yozib qoldiring"
                  {...form.getInputProps("comment")}
                />
              </div>
            </Container>
          </div>

          <div className={Style.UserLoc}>
            <Container size={"82rem"}>
              <div className={Style.locations}>
                <div className={Style.locationsWrapper}>
                  <Select
                    label="Manzili"
                    placeholder="Manzilni tanlang"
                    {...form.getInputProps("adress")}
                    className={Style.phoneType}
                    w={"100%"}
                    size="md"
                    data={[
                      "Toshkent shahri",
                      "Toshkent viloyati",
                      "Samarqand",
                      "Buxoro",
                      "Surxondaryo",
                      "Sirdaryo",
                      "Jizzax",
                      "Xorazm",
                      "Andijon",
                      "Namangan",
                      "Farg'ona",
                      "Qoraqalpog'iston Respublikasi",
                    ]}
                  />

                  <TextInput
                    label="Telegram username"
                    placeholder="@ ni yozmang!"
                    {...form.getInputProps("telegram")}
                    className={Style.phoneType}
                    size="md"
                  />

                  <InputBase
                    label="Telefon raqamingiz"
                    placeholder="+998 90 000 00 00"
                    component={IMaskInput}
                    mask="+998 (00) 000-00-00"
                    w={"100%"}
                    size="md"
                    className={Style.phoneBrand}
                    {...form.getInputProps("phoneNumber")}
                  />
                </div>
              </div>
            </Container>
          </div>
          <div className={Style.finishProjectButton}>
            <button className={Style.showButton} onClick={() => handleCheck()}>
              Ko’rib chiqish
            </button>

            <button className={Style.finishButton}>E’lonni yuklash</button>
          </div>
        </form>
      </Container>
      <Footer />
      <BottomNavigation />
    </div>
  ) : (
    <CheckProduct formData={form.values} setCheck={setCheckProductStatus} />
  );
}
