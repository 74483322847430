import React from "react";
import { Container } from "@mantine/core";
import { Banner, BottomNavigation, Footer, Header } from "../../components";
import { Brands } from "../../sections";
import { Helmet } from "react-helmet";
import { downloadApp } from "../../modules";

import {
  GooglePlayButton,
  AppGalleryButton,
  ButtonsContainer,
} from "react-mobile-app-button";

import { alert } from "../../utils";

export default function Apk() {
  const [apkUrl, setApkUrl] = React.useState<string>("");

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const download = async () => {
    try {
      const link = await downloadApp();
      setApkUrl(link as string);
    } catch (error) {
      console.error(error);
      alert.error("Xatolik yuzaga keldi. Keyinroq urinib ko'ring!");
    }
  };

  React.useEffect(() => {
    getInitialProducts();
  }, []);

  const getInitialProducts = async () => {
    try {
      await download();
    } catch (error) {
      console.error(error);
      alert.error("Xatolik yuzaga keldi. Keyinroq urinib ko'ring!");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Mobil ilova</title>
        <meta
          name="description"
          content="Telmee telefon sotish va sotib olish uchun mo'ljallangan O'zbekistondagi birinchi va yagona platforma! Telefonningizni oson soting yoki o'zingizga qulay telefonni sotib oling"
        />
        <link rel="canonical" href="https://telmee.uz/app" />
      </Helmet>
      <Header />
      <Container size="85rem">
        <Banner />
        <Brands />
        <ButtonsContainer>
          <GooglePlayButton
            url={apkUrl}
            theme={"light"}
            className={"custom-style"}
          />

          <AppGalleryButton
            url={apkUrl} // Use apkUrl instead of IOSUrl
            theme={"light"}
            className={"custom-style"}
          />
        </ButtonsContainer>
      </Container>
      <Footer />
      <BottomNavigation />
    </div>
  );
}
