import React, { useEffect, useState } from "react";
import {
  BottomNavigation,
  Footer,
  Header,
  Product,
  SkeletonLoader,
} from "../../components";
import { Box, Container } from "@mantine/core";
import { EmptyWishes } from "../../components/empty-wishes";
import { IEntity, getUsersLikes } from "../../modules/main";
import { alert } from "../../utils";
import Style from "./wishes.module.scss";

export default function Wishes() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersLikes(setLoading);
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Container size={"85rem"}>
        <div className={Style.likeWrapper}>
          <p className={Style.likeTitle}>Mening istaklarim</p>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <Box>
              {products?.length === 0 ? (
                <EmptyWishes />
              ) : (
                <div className={Style.productsContainer}>
                  {products.map((product) => (
                    <Product key={product.id} product={product} />
                  ))}
                </div>
              )}
            </Box>
          )}
        </div>
      </Container>
      <BottomNavigation />
      <Footer />
    </>
  );
}
