import { useEffect, useState } from "react";
import {
  BottomNavigation,
  Footer,
  Header,
  SkeletonLoader,
} from "../../components";
import { Box, Container } from "@mantine/core";
import { Empty } from "../../components/empty-card";
import { IEntity, getUsersBuckets } from "../../modules/main";
import { alert } from "../../utils";

import Style from "./cart.module.scss";
import SingleCard from "../my-profile/my-cart/single-card";

export default function Cart() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersBuckets(setLoading);
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <>
        <Header />
        <Container size={"85rem"}>
          <div className={Style.cardWrapper}>
            <p className={Style.cardTitle}>Mening savatim</p>
            {loading ? (
              <SkeletonLoader />
            ) : (
              <Box>{products?.length === 0 ? <Empty /> : <SingleCard />}</Box>
            )}
          </div>
        </Container>
        <BottomNavigation />
        <Footer />
      </>
    </div>
  );
}
