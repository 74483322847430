import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./user-slice";
import registerSlice from "./register-slice";
import forgotPasswordSlice from "./forgot-password-slice";
import searchSlice from "./search-slice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    register: registerSlice,
    forgotPassword: forgotPasswordSlice,
    search: searchSlice,
  },
});
