import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Text, Box, PinInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { Container } from "../../../components";

import { useSelector } from "react-redux";
import { selectRegisterInfo } from "../../../redux";
import { verify } from "../../../modules";

import IphoneImage from "../../../assets/images/Group 30.png";
import { alert } from "../../../utils";
import Style from "./verification.module.scss";

export default function Verification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [secondsLeft, setSecondsLeft] = useState(60);
  const registerInfo = useSelector(selectRegisterInfo);

  React.useEffect(() => {
    registerInfo ? console.log() : navigate("auth/register");

    // const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    //   e.preventDefault();
    // };

    // window.addEventListener("beforeunload", handleBeforeUnload);

    // if (isButtonDisabled) {
    //   const timer = setInterval(() => {
    //     setSecondsLeft((prevSeconds) => {
    //       if (prevSeconds === 1) {
    //         setIsButtonDisabled(false);
    //         clearInterval(timer);
    //         return 0;
    //       }
    //       return prevSeconds - 1;
    //     });
    //   }, 1000);

    //   return () => clearInterval(timer);
    // }

    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  const form = useForm({
    initialValues: { code: "" },

    validate: {
      code: (value) => (value.length < 6 ? null : "Parol yetarli emas!"),
    },
  });

  const handleVerify = () => {
    verify(
      //@ts-ignore
      registerInfo?.username,
      registerInfo?.password,
      form.values.code,
      setLoading,
      navigate
    );
  };

  const handleAgainSmsCode = () => {
    alert.success("Sms kod yuborildi!");
  };

  return (
    <div className={Style.loginForm}>
      <Container>
        <div className={Style.formWrapper}>
          <div className={Style.imageContainer}>
            <div>
              <img src={IphoneImage} alt="Iphone Image" />
            </div>
          </div>

          <div className={Style.formContainer}>
            <div className={Style.formBox}>
              <div className={Style.formController}>
                <div className={Style.pathButtons}>
                  <button type="button" className={Style.activePath}>
                    Tasdiqlash
                  </button>
                </div>
                <Text className={Style.verifNumber}>
                  +{registerInfo?.username.slice(0, 4)} ****{" "}
                  {registerInfo?.username.slice(8)} raqamiga sms shaklida
                  yuborilgan parolni kiriting!
                </Text>

                <div>
                  <form onSubmit={form.onSubmit(console.log)}>
                    <Box className={Style.pinInput}>
                      <PinInput
                        {...form.getInputProps("code")}
                        c="#FAC73A80"
                        size="md"
                        length={6}
                        type="number"
                      />
                    </Box>

                    <Box className={Style.checkers}>
                      {/* <Text
                        bg={"none"}
                        c="#b5b5b5"
                        // onClick={() => handleAgainSmsCode()}
                        ff="Almarai"
                        style={{ cursor: "pointer" }}
                      >
                        Kodni qayta yuborish : {secondsLeft}
                      </Text> */}
                    </Box>

                    <Button
                      className={Style.formSubmiter}
                      type="submit"
                      variant="filled"
                      color="#fac73a"
                      mt="sm"
                      onClick={() => handleVerify()}
                      loading={loading}
                    >
                      Tasdiqlash
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
