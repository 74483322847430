import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEntity } from "../modules/auth";

interface RegisterState {
  registerInfo: IEntity.Regiser | null;
}

const initialState: RegisterState = {
  registerInfo: null,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterInfo: (
      state,
      action: PayloadAction<RegisterState["registerInfo"]>
    ) => {
      state.registerInfo = action.payload;
    },
  },
});

export const { setRegisterInfo } = registerSlice.actions;
export const selectRegisterInfo = (state: { register: RegisterState }) =>
  state.register.registerInfo;

export default registerSlice.reducer;
