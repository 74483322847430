import { toast } from "react-hot-toast";

const alert = {
  success: (message: string) => toast.success(message),
  error: (message: string) => toast.error(message),
  warning: (message: string) =>
    toast.success(message, {
      icon: "⚠️",
      iconTheme: {
        primary: "#fac71a",
        secondary: "#fff",
      },
    }),
};

export default alert;
