import { FunctionComponent } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Protected from "./protected";
import {
  ShowProduct,
  Auth,
  Main,
  Page404,
  ProductAdd,
  Cart,
  Wishes,
  Search,
  Profile,
  EditProduct,
  Chat,
  MenuProfile,
  AppDownload,
} from "../pages";

import { MantineProvider } from "@mantine/core";
import { isAuthenticated } from "../redux/user-slice";

import "@mantine/core/styles.css";

const AppRoutes: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <MantineProvider>
        <div>
          <Toaster
            position="top-right"
            containerStyle={{ fontFamily: "Poppins" }}
          />
        </div>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="product/:productId" element={<ShowProduct />} />
            <Route path="app" element={<AppDownload />} />
            <Route path="search" element={<Search />} />

            <Route
              path="addProduct"
              element={
                isAuthenticated ? <ProductAdd /> : <Navigate to="/auth/login" />
              }
            />

            <Route
              path="editProduct/:productId"
              element={
                isAuthenticated ? (
                  <EditProduct />
                ) : (
                  <Navigate to="/auth/login" />
                )
              }
            />

            <Route
              path="cart"
              element={
                isAuthenticated ? <Cart /> : <Navigate to="/auth/login" />
              }
            />

            <Route
              path="wishes"
              element={
                isAuthenticated ? <Wishes /> : <Navigate to="/auth/login" />
              }
            />
            <Route path="chat" element={<Chat />} />

            <Route
              path="myProfile"
              element={
                <Protected allow={isAuthenticated} navigate="/auth/login" />
              }
            >
              <Route index element={<MenuProfile />} />
              <Route path="reports" element={<Profile.MyProfile />} />
              <Route path="settings" element={<Profile.MySettings />} />
              <Route path="wishes" element={<Profile.MyWishes />} />
              <Route path="cart" element={<Profile.MyCart />} />
              <Route path="*" index element={<Navigate to="myProfile" />} />
            </Route>
          </Route>

          <Route
            path="auth"
            element={<Protected allow={!isAuthenticated} navigate="/" />}
          >
            <Route index element={<Navigate to="login" />} />
            <Route path="login" element={<Auth.Login />} />
            <Route path="register" element={<Auth.Register />} />
            <Route path="verification" element={<Auth.Verification />} />
            <Route path="forget-password" element={<Auth.ForgetPassword />} />
            <Route path="forget-verify" element={<Auth.ForgetVerify />} />
            <Route path="forget-new-password" element={<Auth.NewPassword />} />
            <Route path="*" index element={<Navigate to="login" />} />
          </Route>

          <Route path="*" element={<Page404 />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
