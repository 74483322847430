import { Container, Title, Text, Button, Group } from "@mantine/core";
import { Illustration } from "./illutstration";
import classes from "./404.module.scss";
import { Link } from "react-router-dom";

export default function NothingFoundBackground() {
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />
        <div className={classes.content}>
          <Title mt={40} className={classes.title}>
            Noto'gri manzil!
          </Title>
          <Group justify="center">
            <Button bg={"red"} mt={30} size="md">
              <Link style={{ textDecoration: "none", color: "white" }} to={"/"}>
                Ortga qaytish
              </Link>
            </Button>
          </Group>
        </div>
      </div>
    </Container>
  );
}
