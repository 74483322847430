import { GoogleLogin } from "react-google-login";
import { googleAccountConnectToPhoneNumber } from "../../modules";
import { gapi } from "gapi-script";

export default function Connect() {
  const clientId =
    "100876200765-44b117em2k3fbmhourl0tebeeho8cet9.apps.googleusercontent.com";

  const onSuccess = () => {
    googleAccountConnectToPhoneNumber(gapi.auth.getToken().id_token);
  };

  const onFailure = (res: any) => {
    console.log("LOGIN Falied", res);
  };

  return (
    <div id="signInButton">
      <GoogleLogin
        clientId={clientId}
        buttonText="Google account qo'shish"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
}
