import React from "react";
import { Button, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../components";

import { newPasswordForgot } from "../../../modules";

import { useSelector } from "react-redux";
import { selectforgotPasswordInfo } from "../../../redux/forgot-password-slice";

import Style from "./new-password.module.scss";
import IphoneImage from "../../../assets/images/Group 30.png";

export default function Forget() {
  const navigate = useNavigate();
  const forgotPasswordInfo = useSelector(selectforgotPasswordInfo);

  React.useEffect(() => {
    forgotPasswordInfo
      ? console.log("")
      : (window.location.href = "auth/forget-password");

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const form = useForm({
    initialValues: { password: "" },

    validate: {
      password: (value) => {
        if (value.length === 0) {
          return "Parol kiriting!";
        }
        return null;
      },
    },
  });

  const handleSubmit = () => {
    if (form.isValid() && forgotPasswordInfo) {
      newPasswordForgot(
        forgotPasswordInfo.phone_number || "",
        form.values.password,
        forgotPasswordInfo.code || "",
        navigate
      );
    }
  };

  return (
    <div className={Style.forgetForm}>
      <Container>
        <div className={Style.formWrapper}>
          <div className={Style.imageContainer}>
            <div>
              <img src={IphoneImage} alt="Iphone Image" />
            </div>
          </div>

          <div className={Style.formContainer}>
            <div className={Style.formBox}>
              <div className={Style.formController}>
                <div className={Style.pathButtons}>
                  <p>Yangi parolni kiriting</p>
                </div>
                <div>
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <PasswordInput
                      size="lg"
                      placeholder="Password"
                      radius={8}
                      leftSection={<IconLock size={20} />}
                      {...form.getInputProps("password")}
                    />

                    <Button
                      className={Style.formSubmiter}
                      type="submit"
                      variant="filled"
                      color="#fac73a"
                      mt="sm"
                    >
                      Yuborish
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
