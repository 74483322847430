import { useState, useEffect } from "react";
import { EmptyReports, Footer, Header, Product } from "../../components";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  NumberInput,
  Select,
} from "@mantine/core";

import { useSelector } from "react-redux";
import { selectSearch } from "../../redux/search-slice";

import { filterProducts } from "../../modules";
import { IEntity } from "../../modules/main";

import Style from "./search.module.scss";
import { alert } from "../../utils";

export default function Search() {
  const [sortedProducts, setSortedProducts] = useState<IEntity.SingleProduct[]>(
    []
  );
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [location, setLocation] = useState<string>("");
  const [condition, setCondition] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");

  const searchFromRedux = useSelector(selectSearch) as string | null;
  const search =
    searchFromRedux ??
    (new URLSearchParams(window.location.search).get("query") as string);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    handleFilterProducts();
  }, [location, model, maxPrice, minPrice, currency, condition, search]);

  useEffect(() => {
    setLocation(getQueryParam("location"));
    setCondition(getQueryParam("condition"));
    setModel(getQueryParam("model"));
    setCurrency(getQueryParam("currency"));
    setMinPrice(getQueryParam("min_price"));
    setMaxPrice(getQueryParam("max_price"));
  }, []);

  const handleInputChange = (param: string, value: string) => {
    switch (param) {
      case "location":
        setLocation(value);
        break;
      case "condition":
        setCondition(value);
        break;
      case "model":
        setModel(value);
        break;
      case "currency":
        setCurrency(value);
        break;
      case "min_price":
        setMinPrice(value);
        break;
      case "max_price":
        setMaxPrice(value);
        break;
      default:
        break;
    }
    updateUrlParams(param, value);
  };

  const handleButtonClick = (buttonName: string, currencyValue: string) => {
    setActiveButton(buttonName);
    handleInputChange("currency", currencyValue);
  };

  const updateUrlParams = (param: string, value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.history.pushState({}, "", url.toString());
  };

  const getQueryParam = (param: string): string => {
    return new URLSearchParams(window.location.search).get(param) || "";
  };

  const handleFilterProducts = async () => {
    try {
      const queryParams = new URLSearchParams({
        name: search,
        location,
        condition,
        model,
        currency,
        min_price: minPrice,
        max_price: maxPrice,
      });

      const response = await filterProducts(queryParams.toString());
      setSortedProducts(response);
    } catch (error) {
      alert.error("Error filtering products: " + error);
    }
  };

  const handleRemoveSort = () => {
    setLocation("");
    setCondition("");
    setModel("");
    setCurrency("");
    setMinPrice("");
    setMaxPrice("");

    window.history.pushState({}, document.title, window.location.pathname);
  };

  return (
    <div>
      <Header />
      <Container size={"85rem"}>
        <div
          style={{ display: isHidden ? "none" : "block" }}
          className={Style.fillterProductWrapper}
        >
          <p className={Style.filterTitle}>Filter</p>
          <div className={Style.filterForm}>
            <div className={Style.fomrExtra}>
              <Select
                label="Manzili"
                placeholder="Manzilni tanlang"
                data={[
                  "Toshkent shahri",
                  "Toshkent viloyati",
                  "Samarqand",
                  "Buxoro",
                  "Surxondaryo",
                  "Sirdaryo",
                  "Jizzax",
                  "Xorazm",
                  "Andijon",
                  "Namangan",
                  "Farg'ona",
                  "Qoraqalpog'iston Respublikasi",
                ]}
                onChange={(value) =>
                  handleInputChange("location", value as string)
                }
                value={location}
              />
            </div>

            <div className={Style.filterPriceType}>
              <Input.Wrapper className={Style.userNarx} label="Narx">
                <Flex className={Style.narxCOnt}>
                  <NumberInput
                    placeholder="Dan:"
                    value={minPrice}
                    onChange={(value) =>
                      handleInputChange("min_price", value as string)
                    }
                  />

                  <NumberInput
                    value={maxPrice}
                    placeholder="Gacha:"
                    onChange={(value) =>
                      handleInputChange("max_price", value as string)
                    }
                  />
                </Flex>
              </Input.Wrapper>
            </div>

            <div className={Style.fomrExtra}>
              <Select
                label="Holati"
                placeholder="Telefon holatini tanlang"
                data={["B/U", "NEW"]}
                onChange={(value) =>
                  handleInputChange("condition", value as string)
                }
                value={condition}
              />
            </div>
          </div>

          <p className={Style.cleanFilter} onClick={() => handleRemoveSort()}>
            Filtirni o’chirish
          </p>

          <div className={Style.line}></div>

          <div className={Style.filterFormSecond}>
            <div className={Style.fomrExtra}>
              <Select
                label="Modelni tanlang"
                placeholder="Tanlangan"
                data={[
                  "iPhone",
                  "Samsung",
                  "Redmi",
                  "Xiaomi",
                  "Huawei",
                  "Artel",
                  "Vivo",
                  "Lg",
                  "Honor",
                  "One Plus",
                  "Google",
                  "Motorola",
                  "Sony",
                  "Nokia",
                ]}
                onChange={(value) =>
                  handleInputChange("model", value as string)
                }
                value={model}
              />
            </div>

            <div className={Style.filterPriceType}>
              <label htmlFor="">Narx turini tanlang</label>
              <Flex className={Style.priceType}>
                <Button
                  onClick={() => handleButtonClick("UZS", "uzs")}
                  color="#889098"
                  variant="outline"
                  className={currency === "uzs" ? Style.active : ""}
                >
                  UZS
                </Button>

                <Button
                  onClick={() => handleButtonClick("Y.E", "y.e")}
                  color="#889098"
                  variant="outline"
                  className={currency === "y.e" ? Style.active : ""}
                >
                  Y.E
                </Button>
              </Flex>
            </div>
          </div>
          <p className={Style.cleanFilter}>Telefon savdosi</p>
        </div>

        <Box mt={30}>
          {sortedProducts.length === 0 ? <EmptyReports /> : null}
          {sortedProducts ? (
            <div className={Style.productsContainer}>
              {sortedProducts.map((product) => (
                <Product key={product.id} product={product} />
              ))}
            </div>
          ) : null}
          <Button
            onClick={() => setIsHidden(!isHidden)}
            className={Style.ftbtn}
          >
            {isHidden ? "Filterni yoqish" : "Filterni o'chirish"}
          </Button>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
