import { Badge, Button, Container, LoadingOverlay } from "@mantine/core";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getMe } from "../../modules/auth/service";
import { selectUser, setUser } from "../../redux/user-slice";
import { setSearch } from "../../redux/search-slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { alert, getAccessToken } from "../../utils";

import Logo from "../../assets/images/logo.svg";
import Style from "./header.module.scss";
import { ProfileModal } from "../profile-modal";

export default function Header() {
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  useEffect(() => {
    const token = getAccessToken();
    const getAndSaveUser = async () => {
      try {
        setLoading(true);
        const response = await getMe();
        dispatch(setUser(response));
      } catch (error) {
        alert.error("Error getting user");
      } finally {
        setLoading(false);
      }
    };

    if (token) getAndSaveUser();

    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleAddProduct = () => {
    user ? navigate("/addProduct") : navigate("/auth/login");
  };

  const handleSearch = () => {
    if (searchItem.length > 0) {
      dispatch(setSearch(searchItem));
      navigate(`/search?query=${searchItem}`);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={`${Style.header} ${scrolled ? Style.scrolled : ""}`}>
      {loading && (
        <LoadingOverlay
          visible={loading}
          zIndex={100}
          overlayProps={{ radius: "sm", blur: 2 }}
          loaderProps={{ color: "#fac71a", type: "bars" }}
        />
      )}
      <Container className={Style.headerContainer} size={"85rem"}>
        <div className={Style.hedaerForm}>
          <a
            onClick={() => {
              navigate("/");
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className={Style.logo}
          >
            <img width={80} height={80} src={Logo} alt="" />
            <p>Telmee</p>
          </a>
          <div className={Style.searchINput}>
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchItem(e.target.value);
              }}
              defaultValue={query as string}
              type="text"
              placeholder="Telefon izlash"
              onKeyPress={handleKeyPress}
            />

            <button onClick={() => handleSearch()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 26 27"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="8.75"
                  stroke="white"
                  stroke-width="2.5"
                />
                <path
                  d="M16 17L24 25"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className={Style.headerActions}>
          <div className={Style.headerIcons}>
            <div>
              <svg
                onClick={() => navigate("/chat")}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M4.66644 12.6667C4.66644 6.77563 9.44167 2 15.3322 2C21.2228 2 25.998 6.77563 25.998 12.6667C25.998 14.2 25.6745 15.6578 25.092 16.9755L26 23.3323L20.5528 21.9704C19.0096 22.8383 17.2287 23.3333 15.3322 23.3333M2.00126 19.3333C2.00126 20.2917 2.20344 21.2028 2.56747 22.0264L2 25.9994L5.4042 25.1482C6.36857 25.6906 7.48153 26 8.66674 26C12.348 26 15.3322 23.0152 15.3322 19.3333C15.3322 15.6514 12.348 12.6667 8.66674 12.6667C4.9855 12.6667 2.00126 15.6514 2.00126 19.3333Z"
                  stroke="black"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                onClick={() => navigate("/wishes")}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.04334 6.13829C5.35208 4.82521 7.12688 4.08756 8.97744 4.08756C10.828 4.08756 12.6028 4.82521 13.9115 6.13829L15.9564 8.18875L18.0012 6.13829C18.645 5.46932 19.4151 4.93573 20.2665 4.56864C21.118 4.20156 22.0338 4.00835 22.9604 4.00026C23.8871 3.99218 24.8061 4.1694 25.6638 4.52158C26.5214 4.87375 27.3006 5.39383 27.9559 6.05147C28.6112 6.70911 29.1294 7.49114 29.4803 8.35192C29.8312 9.21271 30.0078 10.135 29.9997 11.065C29.9917 11.995 29.7992 12.9141 29.4334 13.7686C29.0676 14.6232 28.536 15.396 27.8694 16.0422L15.9564 28L4.04334 16.0422C2.73499 14.7287 2 12.9475 2 11.0902C2 9.23297 2.73499 7.45176 4.04334 6.13829V6.13829Z"
                  stroke="black"
                  stroke-width="2.5"
                  stroke-linejoin="round"
                />
              </svg>
              <Badge size="xs" color="#fac73a" circle className={Style.badge}>
                0
              </Badge>
            </div>

            <div>
              <svg
                onClick={() => navigate("/cart")}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clip-path="url(#clip0_88_1910)">
                  <path
                    d="M2 0.75C1.30964 0.75 0.75 1.30964 0.75 2C0.75 2.69036 1.30964 3.25 2 3.25V0.75ZM5.34328 2L6.55064 1.67629C6.40415 1.1299 5.90897 0.75 5.34328 0.75V2ZM10.7761 22.2631L9.56876 22.5868C9.73054 23.1902 10.3129 23.5815 10.9327 23.5033L10.7761 22.2631ZM27.4925 20.1524L27.6491 21.3925C28.1802 21.3255 28.6097 20.9276 28.7171 20.4032L27.4925 20.1524ZM30 7.91007L31.2246 8.16089C31.2999 7.79302 31.2059 7.41077 30.9685 7.11983C30.7311 6.82888 30.3755 6.66007 30 6.66007V7.91007ZM2 3.25H5.34328V0.75H2V3.25ZM10.9327 23.5033L27.6491 21.3925L27.3359 18.9122L10.6195 21.023L10.9327 23.5033ZM28.7171 20.4032L31.2246 8.16089L28.7754 7.65926L26.268 19.9015L28.7171 20.4032ZM4.13593 2.32371L5.7205 8.23378L8.13522 7.58636L6.55064 1.67629L4.13593 2.32371ZM5.7205 8.23378L9.56876 22.5868L11.9835 21.9394L8.13522 7.58636L5.7205 8.23378ZM30 6.66007H6.92786V9.16007H30V6.66007ZM14.75 27.7903C14.75 28.3323 14.3184 28.75 13.8125 28.75V31.25C15.7228 31.25 17.25 29.6891 17.25 27.7903H14.75ZM13.8125 28.75C13.3066 28.75 12.875 28.3323 12.875 27.7903H10.375C10.375 29.6891 11.9022 31.25 13.8125 31.25V28.75ZM12.875 27.7903C12.875 27.2484 13.3066 26.8306 13.8125 26.8306V24.3306C11.9022 24.3306 10.375 25.8915 10.375 27.7903H12.875ZM13.8125 26.8306C14.3184 26.8306 14.75 27.2484 14.75 27.7903H17.25C17.25 25.8915 15.7228 24.3306 13.8125 24.3306V26.8306ZM26.4167 27.7903C26.4167 28.3323 25.9851 28.75 25.4792 28.75V31.25C27.3895 31.25 28.9167 29.6891 28.9167 27.7903H26.4167ZM25.4792 28.75C24.9733 28.75 24.5417 28.3323 24.5417 27.7903H22.0417C22.0417 29.6891 23.5688 31.25 25.4792 31.25V28.75ZM24.5417 27.7903C24.5417 27.2484 24.9733 26.8306 25.4792 26.8306V24.3306C23.5688 24.3306 22.0417 25.8915 22.0417 27.7903H24.5417ZM25.4792 26.8306C25.9851 26.8306 26.4167 27.2484 26.4167 27.7903H28.9167C28.9167 25.8915 27.3895 24.3306 25.4792 24.3306V26.8306Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_88_1910">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Badge size="xs" color="#fac73a" circle className={Style.badge}>
                0
              </Badge>
            </div>
          </div>

          <div className={Style.headerButtons}>
            <ProfileModal />

            <Button
              onClick={() => handleAddProduct()}
              className={Style.AddProduct}
              variant="default"
            >
              E'lon joylash
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
