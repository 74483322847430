import React from "react";

export default function Avatar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
    >
      <rect width="110" height="110" rx="55" fill="#F5F5F5" />
      <path
        d="M34 82C34 76.5441 36.2125 71.3117 40.1508 67.4538C44.089 63.5959 49.4305 61.4286 55 61.4286C60.5695 61.4286 65.911 63.5959 69.8492 67.4538C73.7875 71.3117 76 76.5441 76 82H70.75C70.75 77.9081 69.0906 73.9838 66.1369 71.0904C63.1832 68.1969 59.1772 66.5714 55 66.5714C50.8228 66.5714 46.8168 68.1969 43.8631 71.0904C40.9094 73.9838 39.25 77.9081 39.25 82H34ZM55 58.8571C51.8849 58.8571 48.8398 57.9523 46.2498 56.257C43.6597 54.5616 41.641 52.152 40.4489 49.3328C39.2568 46.5136 38.9449 43.4115 39.5526 40.4186C40.1603 37.4258 41.6604 34.6767 43.8631 32.5189C46.0657 30.3612 48.8721 28.8918 51.9273 28.2965C54.9825 27.7011 58.1493 28.0067 61.0273 29.1744C63.9052 30.3422 66.365 32.3197 68.0956 34.8569C69.8263 37.3941 70.75 40.3771 70.75 43.4286C70.75 47.5205 69.0906 51.4448 66.1369 54.3382C63.1832 57.2316 59.1772 58.8571 55 58.8571ZM55 53.7143C57.0767 53.7143 59.1068 53.111 60.8335 51.9808C62.5602 50.8506 63.906 49.2442 64.7007 47.3647C65.4955 45.4853 65.7034 43.4172 65.2982 41.4219C64.8931 39.4267 63.8931 37.594 62.4246 36.1555C60.9562 34.717 59.0852 33.7374 57.0484 33.3405C55.0116 32.9436 52.9004 33.1473 50.9818 33.9258C49.0632 34.7043 47.4233 36.0227 46.2696 37.7141C45.1158 39.4056 44.5 41.3943 44.5 43.4286C44.5 46.1565 45.6062 48.7727 47.5754 50.7017C49.5445 52.6306 52.2152 53.7143 55 53.7143Z"
        fill="#fac73a"
      />
    </svg>
  );
}
