import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Flex, LoadingOverlay, NumberFormatter } from "@mantine/core";
import {
  IEntity,
  deleteUsersBucket,
  getUsersBuckets,
} from "../../../modules/main";

import { alert } from "../../../utils";
import Style from "./single-card.module.scss";

export default function SingleCard() {
  const url = process.env.REACT_APP_BASE_URL as string;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      await getProducts();
    };
    fetchData();
  }, [onclick]);

  const removeProductFromBasket = async (id: string) => {
    await deleteUsersBucket(id);
    await getProducts();
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersBuckets(setLoading);
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={Style.reportWrapper}>
        {loading && (
          <LoadingOverlay
            visible={loading}
            zIndex={100}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "#fac71a", type: "bars" }}
          />
        )}
        {products.map((product) => (
          <div key={product.id} className={Style.reportBox}>
            <div className={Style.reportInfos}>
              <div className={Style.reportImage}>
                <img src={`${url}/${product.images.image}`} alt="product-img" />
              </div>

              <div className={Style.reportedinfo}>
                <div className={Style.phoneInfo}>
                  <p>{product.phoneName}</p>
                  <p>
                    <NumberFormatter thousandSeparator value={product.cost} />
                    {""}
                    {product.costType}
                  </p>
                </div>

                <div className={Style.userLocation}>
                  <p>Telefon savdosi - {product.phoneMarka}</p>
                  <Flex gap={5} align={"center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M14 24.5C14 24.5 22.5 17.1957 22.5 11.7174C22.5 7.17905 18.6944 3.5 14 3.5C9.30558 3.5 5.5 7.17905 5.5 11.7174C5.5 17.1957 14 24.5 14 24.5Z"
                        stroke="black"
                        stroke-width="2"
                      />
                      <path
                        d="M16.7156 11.3752C16.7156 12.8249 15.5 14.0002 14.0003 14.0002C12.5007 14.0002 11.2851 12.8249 11.2851 11.3752C11.2851 9.92542 12.5007 8.75017 14.0003 8.75017C15.5 8.75017 16.7156 9.92542 16.7156 11.3752Z"
                        stroke="black"
                        stroke-width="2"
                      />
                    </svg>
                    <p>{product.adress}</p>
                  </Flex>

                  <Flex gap={5} align={"center"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M8.57192 9.66491H19.8811M8.06213 3.50011V5.51128M8.06213 5.51128L19.9368 5.51106M8.06213 5.51128C6.0946 5.51128 4.4998 7.10522 4.4999 9.07167L4.50045 20.9397C4.50054 22.906 6.09545 24.5 8.06284 24.5H19.9375C21.905 24.5 23.5 22.9058 23.4999 20.9394L23.4993 9.07133C23.4993 7.10502 21.9042 5.51106 19.9368 5.51106M19.9368 3.5V5.51106M11.6254 20.3462V13.2253L9.25047 15.0055M18.1565 20.3462V13.2253L15.7815 15.0055"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>{product.time.slice(0, 10)} </p>
                  </Flex>
                </div>

                <div className={Style.reportResult}>
                  <div className={Style.reportButton}>
                    <Button
                      onClick={() => navigate(`/product/${product.id}`)}
                      color="#fac73a"
                      variant="outline"
                    >
                      Telefonni ko'rish
                    </Button>

                    <Button
                      onClick={() => {
                        removeProductFromBasket(
                          product.id.toString() as string
                        );
                      }}
                      color="#fac73a"
                    >
                      Savatdan olish
                    </Button>
                  </div>
                </div>

                <p className={Style.line} />
              </div>
            </div>
            <p className={Style.reportId}>ID: {product.id}</p>
          </div>
        ))}
        <p className={Style.totalReport}>
          Jami savatdagilar: {products.length}
        </p>
      </div>
    </>
  );
}
