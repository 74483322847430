import React from "react";
import { useNavigate } from "react-router-dom";

import Style from "./navigation.module.scss";

export default function Navigation() {
  const [homeActive, setHomeActive] = React.useState(false);
  const [reportActive, setReportActive] = React.useState(false);
  const [cartActiv, setCartActiv] = React.useState(false);
  const [wishesActive, setWishesActive] = React.useState(false);
  const [myProfileActive, setMyProfileActive] = React.useState(false);

  const param = window.location.pathname;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (param === "/") setHomeActive(true);
    if (param === "/addProduct") setReportActive(true);
    if (param === "/wishes") setWishesActive(true);
    if (param === "/cart") setCartActiv(true);
    if (param.includes("/myProfile")) setMyProfileActive(true);
  }, [param]);

  const handleItemClick = (itemName: string) => {
    setHomeActive(false);
    setReportActive(false);
    setCartActiv(false);
    setWishesActive(false);
    setMyProfileActive(false);

    if (itemName === "home") setHomeActive(true);
    if (itemName === "addProduct") setReportActive(true);
    if (itemName === "wishes") setWishesActive(true);
    if (itemName === "cart") setCartActiv(true);
    if (itemName === "myProfile") setMyProfileActive(true);
  };

  return (
    <div className={Style.navigateWrapper}>
      <div className={Style.navWrap}>
        <div className={Style.navItems}>
          <div
            className={Style.item}
            onClick={() => {
              handleItemClick("home");
              navigate("/");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M21.5605 23.706H16.5899C15.8272 23.706 15.2092 23.0916 15.2092 22.3334V17.3922C15.2092 16.7856 14.7149 16.2942 14.1046 16.2942H11.8954C11.2852 16.2942 10.7909 16.7856 10.7909 17.3922V22.3334C10.7909 23.0916 10.1729 23.706 9.41014 23.706H4.43956C3.67685 23.706 3.05884 23.0916 3.05884 22.3334V11.8543C3.05884 10.5921 3.64095 9.3996 4.63783 8.6189L12.4869 2.47042C12.7879 2.23544 13.2121 2.23544 13.5125 2.47042L21.3628 8.6189C22.3596 9.3996 22.9412 10.5915 22.9412 11.8532V22.3334C22.9412 23.0916 22.3232 23.706 21.5605 23.706Z"
                fill={homeActive ? "#fac71a" : "black"}
              />
            </svg>

            <p className={homeActive ? Style.active : ""}>Bosh sahifa</p>
          </div>

          <div
            className={Style.item}
            onClick={() => {
              handleItemClick("wishes");
              navigate("/wishes");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.45953 6.42552C5.39434 5.55014 6.66206 5.05838 7.98389 5.05838C9.30572 5.05838 10.5734 5.55014 11.5082 6.42552L12.9688 7.7925L14.4294 6.42552C14.8893 5.97954 15.4393 5.62382 16.0475 5.3791C16.6557 5.13438 17.3098 5.00556 17.9717 5.00018C18.6336 4.99479 19.29 5.11293 19.9027 5.34772C20.5153 5.5825 21.0719 5.92922 21.5399 6.36765C22.008 6.80607 22.3781 7.32742 22.6288 7.90128C22.8794 8.47514 23.0056 9.09001 22.9998 9.71001C22.9941 10.33 22.8565 10.9427 22.5953 11.5124C22.334 12.0821 21.9543 12.5974 21.4782 13.0281L12.9688 21L4.45953 13.0281C3.52499 12.1525 3 10.965 3 9.72682C3 8.48865 3.52499 7.30117 4.45953 6.42552V6.42552Z"
                stroke-width="1.8"
                stroke-linejoin="round"
                fill={wishesActive ? "#fac71a" : "white"}
                stroke={wishesActive ? "#fac71a" : "black"}
              />
            </svg>

            <p className={wishesActive ? Style.active : ""}>Istaklar</p>
          </div>

          <div
            className={Style.item}
            onClick={() => {
              handleItemClick("addProduct");
              navigate("/addProduct");
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill={reportActive ? "#fac71a" : "white"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.9"
                y="0.9"
                width="20.2"
                height="20.2"
                rx="10.1"
                stroke={reportActive ? "fac71a" : "black"}
                stroke-width="1.8"
              />
              <path
                d="M11 6L11 16M16 11L6 11"
                stroke={reportActive ? "white" : "black"}
                stroke-width="1.8"
                stroke-linecap="round"
              />
            </svg>
            <p className={reportActive ? Style.active : ""}>E'lon joylash</p>
          </div>

          <div
            className={Style.item}
            onClick={() => {
              handleItemClick("cart");
              navigate("/cart");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill={cartActiv ? "#fac71a" : "none"}
            >
              <path
                d="M1 0.1C0.502944 0.1 0.1 0.502944 0.1 1C0.1 1.49706 0.502944 1.9 1 1.9V0.1ZM3.38806 1L4.25736 0.766929C4.15188 0.37353 3.79535 0.1 3.38806 0.1V1ZM7.26866 15.4736L6.39936 15.7067C6.51584 16.1412 6.93516 16.4229 7.3814 16.3666L7.26866 15.4736ZM19.209 13.966L19.3217 14.8589C19.7041 14.8106 20.0133 14.5242 20.0907 14.1466L19.209 13.966ZM21 5.22148L21.8817 5.40207C21.9359 5.1372 21.8682 4.86198 21.6973 4.6525C21.5264 4.44302 21.2704 4.32148 21 4.32148V5.22148ZM1 1.9H3.38806V0.1H1V1.9ZM7.3814 16.3666L19.3217 14.8589L19.0962 13.0731L7.15591 14.5807L7.3814 16.3666ZM20.0907 14.1466L21.8817 5.40207L20.1183 5.04089L18.3273 13.7854L20.0907 14.1466ZM2.51876 1.23307L3.6506 5.45455L5.3892 4.98841L4.25736 0.766929L2.51876 1.23307ZM3.6506 5.45455L6.39936 15.7067L8.13795 15.2406L5.3892 4.98841L3.6506 5.45455ZM21 4.32148H4.5199V6.12148H21V4.32148ZM10.1 19.4217C10.1 19.8049 9.79485 20.1 9.4375 20.1V21.9C10.806 21.9 11.9 20.7818 11.9 19.4217H10.1ZM9.4375 20.1C9.08015 20.1 8.775 19.8049 8.775 19.4217H6.975C6.975 20.7818 8.06896 21.9 9.4375 21.9V20.1ZM8.775 19.4217C8.775 19.0384 9.08015 18.7433 9.4375 18.7433V16.9433C8.06896 16.9433 6.975 18.0615 6.975 19.4217H8.775ZM9.4375 18.7433C9.79485 18.7433 10.1 19.0384 10.1 19.4217H11.9C11.9 18.0615 10.806 16.9433 9.4375 16.9433V18.7433ZM18.4333 19.4217C18.4333 19.8049 18.1282 20.1 17.7708 20.1V21.9C19.1394 21.9 20.2333 20.7818 20.2333 19.4217H18.4333ZM17.7708 20.1C17.4135 20.1 17.1083 19.8049 17.1083 19.4217H15.3083C15.3083 20.7818 16.4023 21.9 17.7708 21.9V20.1ZM17.1083 19.4217C17.1083 19.0384 17.4135 18.7433 17.7708 18.7433V16.9433C16.4023 16.9433 15.3083 18.0615 15.3083 19.4217H17.1083ZM17.7708 18.7433C18.1282 18.7433 18.4333 19.0384 18.4333 19.4217H20.2333C20.2333 18.0615 19.1394 16.9433 17.7708 16.9433V18.7433Z"
                fill={cartActiv ? "#fac71a" : "black"}
              />
            </svg>

            <p className={cartActiv ? Style.active : ""}>Savat</p>
          </div>

          <div
            className={Style.item}
            onClick={() => {
              handleItemClick("myProfile");
              navigate("/myProfile");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill={myProfileActive ? "#fac71a" : "none"}
            >
              <path
                d="M3.5 22.4921C3.5 18.1623 6.62143 14.6523 13 14.6523C19.3786 14.6523 22.5 18.1623 22.5 22.4921C22.5 23.1809 22.0663 23.7393 21.5314 23.7393H4.46863C3.93367 23.7393 3.5 23.1809 3.5 22.4921Z"
                stroke="black"
                stroke-width="1.8"
              />
              <path
                d="M17.1304 6.39142C17.1304 8.6726 15.2811 10.5219 12.9999 10.5219C10.7188 10.5219 8.86951 8.6726 8.86951 6.39142C8.86951 4.11024 10.7188 2.26099 12.9999 2.26099C15.2811 2.26099 17.1304 4.11024 17.1304 6.39142Z"
                stroke="black"
                stroke-width="1.8"
              />
            </svg>

            <p className={myProfileActive ? Style.active : ""}>Hisobim</p>
          </div>
        </div>
      </div>
    </div>
  );
}
