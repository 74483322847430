import React, { useState, useEffect } from "react";
import { Product, SkeletonLoader } from "../../components";
import { alert } from "../../utils";
import { IEntity } from "../../modules/main";
import { getSimilarProducts } from "../../modules";

import Style from "../section.module.scss";

interface ProductProps {
  id: string;
}

const SimilarProducts: React.FC<ProductProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  useEffect(() => {
    getProducts();
  }, [id]);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getSimilarProducts(id);
      setProducts(productsData.related_products);
    } catch (error) {
      alert.error("Xatolik yuzaga keldi. Keyinroq urinib ko'ring!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <p className={Style.productTitle}>O'xshash Smartfonlar</p>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className={Style.productsContainer}>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SimilarProducts;
