import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, NumberFormatter } from "@mantine/core";

import { IEntity } from "../../modules/main/types";
import Style from "./product.module.scss";
import { addBucket, addLike } from "../../modules";

interface ProductProps {
  product: IEntity.SingleProduct;
}

export default function Product({ product }: ProductProps) {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL as string;
  const [isLiked, setIsLiked] = useState(product.liked_status);
  const [isCart, setIsCart] = useState(product.isCart);
  const [countProducts, setCountProducts] = useState(10);

  const addLikeToProduct = (id: string) => {
    setIsLiked(!isLiked);
    addLike(id);
  };

  const addCart = (id: string) => {
    setIsCart(true);
    addBucket(id);
  };

  useEffect(() => {
    const updateSlides = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1350 && screenWidth > 892) {
        setCountProducts(8);
      } else if (screenWidth <= 892) {
        setCountProducts(6);
      } else {
        setCountProducts(10);
      }
    };

    updateSlides();

    const handleResize = () => {
      updateSlides();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div onClick={() => navigate(`/product/${product.id}`)} key={product.id}>
      <div className={Style.productBox}>
        <div className={Style.productImage}>
          <img src={`${url}/${product.images.image}`} alt="" />
        </div>

        <div className={Style.productDescription}>
          <p className={Style.productName}>{product.phoneName}</p>

          <div className={Style.productPriceBox}>
            <p className={Style.productPrice}>
              <NumberFormatter thousandSeparator value={product.cost} />
            </p>
            <p className={Style.productPriceType}>{product.costType}</p>
          </div>

          <div className={Style.cardButtons}>
            <Button
              variant="filled"
              color="#fac73a"
              className={Style.productStatus}
            >
              {product.isNew ? "NEW" : "B/U"}
            </Button>

            <Flex
              style={{ justifyContent: "center" }}
              align={"center"}
              gap={10}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 30 30"
                fill={isLiked ? "#fac71a" : "none"}
                onClick={(e) => {
                  e.stopPropagation();
                  addLikeToProduct(product.id.toString());
                }}
                className={Style.like}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.04334 5.04919C4.35208 3.79083 6.12688 3.08392 7.97744 3.08392C9.82801 3.08392 11.6028 3.79083 12.9115 5.04919L14.9564 7.01422L17.0012 5.04919C17.645 4.4081 18.4151 3.89674 19.2665 3.54495C20.118 3.19317 21.0338 3.008 21.9604 3.00025C22.8871 2.99251 23.8061 3.16234 24.6638 3.49984C25.5214 3.83734 26.3006 4.33575 26.9559 4.96599C27.6112 5.59623 28.1294 6.34567 28.4803 7.17059C28.8312 7.99551 29.0078 8.87938 28.9997 9.77064C28.9917 10.6619 28.7992 11.5427 28.4334 12.3616C28.0676 13.1805 27.536 13.9212 26.8694 14.5404L14.9564 26L3.04334 14.5404C1.73499 13.2817 1 11.5747 1 9.7948C1 8.01493 1.73499 6.30793 3.04334 5.04919V5.04919Z"
                  stroke={isLiked ? "#fac71a" : "black"}
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>

              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  isCart ? navigate("/cart") : addCart(product.id.toString());
                }}
                style={{
                  border: "solid 1px #FAC73A",
                  borderRadius: "8px",
                  background: `${isCart ? "#fac71a" : "none"}`,
                }}
                className={Style.productCard}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M1 0.015625C0.447715 0.015625 0 0.46334 0 1.01562C0 1.56791 0.447715 2.01562 1 2.01562V0.015625ZM3.29254 1.01562L4.25776 0.75421C4.13971 0.318307 3.74414 0.015625 3.29254 0.015625V1.01562ZM7.01791 14.7709L6.05268 15.0323C6.18295 15.5133 6.64747 15.8249 7.14194 15.7631L7.01791 14.7709ZM18.4806 13.338L18.6046 14.3303C19.0293 14.2772 19.3731 13.9597 19.4599 13.5406L18.4806 13.338ZM20.2 5.02757L21.1793 5.23017C21.2402 4.93566 21.1653 4.62936 20.9754 4.39614C20.7855 4.16293 20.5007 4.02757 20.2 4.02757V5.02757ZM4.3791 5.02757L3.41388 5.28898L4.3791 5.02757ZM1 2.01562H3.29254V0.015625H1V2.01562ZM7.14194 15.7631L18.6046 14.3303L18.3566 12.3457L6.89387 13.7786L7.14194 15.7631ZM19.4599 13.5406L21.1793 5.23017L19.2207 4.82496L17.5013 13.1354L19.4599 13.5406ZM2.32731 1.27704L3.41388 5.28898L5.34433 4.76615L4.25776 0.75421L2.32731 1.27704ZM3.41388 5.28898L6.05268 15.0323L7.98314 14.5094L5.34433 4.76615L3.41388 5.28898ZM20.2 4.02757H4.3791V6.02757H20.2V4.02757ZM9.6 18.5229C9.6 18.799 9.37614 19.0229 9.1 19.0229V21.0229C10.4807 21.0229 11.6 19.9036 11.6 18.5229H9.6ZM9.1 19.0229C8.82386 19.0229 8.6 18.799 8.6 18.5229H6.6C6.6 19.9036 7.71929 21.0229 9.1 21.0229V19.0229ZM8.6 18.5229C8.6 18.2468 8.82386 18.0229 9.1 18.0229V16.0229C7.71929 16.0229 6.6 17.1422 6.6 18.5229H8.6ZM9.1 18.0229C9.37614 18.0229 9.6 18.2468 9.6 18.5229H11.6C11.6 17.1422 10.4807 16.0229 9.1 16.0229V18.0229ZM17.6 18.5229C17.6 18.799 17.3761 19.0229 17.1 19.0229V21.0229C18.4807 21.0229 19.6 19.9036 19.6 18.5229H17.6ZM17.1 19.0229C16.8239 19.0229 16.6 18.799 16.6 18.5229H14.6C14.6 19.9036 15.7193 21.0229 17.1 21.0229V19.0229ZM16.6 18.5229C16.6 18.2468 16.8239 18.0229 17.1 18.0229V16.0229C15.7193 16.0229 14.6 17.1422 14.6 18.5229H16.6ZM17.1 18.0229C17.3761 18.0229 17.6 18.2468 17.6 18.5229H19.6C19.6 17.1422 18.4807 16.0229 17.1 16.0229V18.0229Z"
                    fill={isCart ? "#fff" : "black"}
                  />
                </svg>
              </Box>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}
