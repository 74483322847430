import React, { useState } from "react";
import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { useSelector } from "react-redux";
import { selectUser } from "../../../redux";

import { editUser } from "../../../modules";

import AvatarSvg from "../../../assets/images/avatar";
import Style from "./my-settings.module.scss";
import { alert } from "../../../utils";
import { GooglePhone } from "../../../components";
import { gapi } from "gapi-script";

export default function MySettings() {
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const clientId =
    "100876200765-44b117em2k3fbmhourl0tebeeho8cet9.apps.googleusercontent.com";

  const form = useForm({
    initialValues: {
      father_name: user?.father_name || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
    },

    validate: {
      father_name: (value) => {
        if (value.length < 4) {
          return "Kamida 4ta belgidan iborat bo'lishi kerak!";
        }
        return null;
      },
      first_name: (value) => {
        if (value.length < 4) {
          return "Kamida 4ta belgidan iborat bo'lishi kerak!";
        }
        return null;
      },
      last_name: (value) => {
        if (value.length < 4) {
          return "Kamida 4ta belgian iborat bo'lishi kerak!";
        }
        return null;
      },
    },
  });

  React.useEffect(() => {
    form.setValues({
      father_name: user?.father_name || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
    });
  }, [user]);

  const handleSubmit = () => {
    if (form.isValid()) {
      setLoading(true);
      editUser(form.values, setLoading);
    } else {
      alert.error("Barcha ma'lumotlarni to'gri kirting");
    }
  };

  React.useEffect(() => {
    function start() {
      gapi.client.init({
        clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className={Style.settingsWrap}>
      <div className={Style.main}>
        {loading && (
          <LoadingOverlay
            visible={loading}
            zIndex={100}
            top={250}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ color: "#fac71a", type: "bars" }}
          />
        )}
        <div className={Style.avatar}>
          <AvatarSvg />
          <p>{user?.first_name}</p>
          <p>+{user?.phone_number}</p>
        </div>

        <form className={Style.inputs} onSubmit={form.onSubmit(handleSubmit)}>
          <p>Shaxsiy ma’lumotlarim</p>
          <div className={Style.userInfoInputsWrapper}>
            <div className={Style.userInfoInputWrap}>
              <TextInput
                label="Ism"
                placeholder="Ismingizni kirting"
                {...form.getInputProps("first_name")}
                className={Style.inputName}
                size="md"
              />

              <TextInput
                label="Familya"
                placeholder="Familyangizni kiriting"
                {...form.getInputProps("last_name")}
                className={Style.firstName}
                size="md"
              />

              <TextInput
                label="Otasining ismi"
                placeholder="Otangizning ismini kiriting"
                className={Style.fatherName}
                {...form.getInputProps("father_name")}
                size="md"
              />
            </div>

            <div className={Style.userEmailInfoWrapper}>
              <TextInput
                label="Elektron pochta"
                placeholder="Emailingizni kiriting"
                size="md"
                {...form.getInputProps("email")}
                className={Style.userEmail}
                disabled
              />

              <TextInput
                label="Parol"
                placeholder="Parolingizni kiriting"
                className={Style.userEmailPassword}
                size="md"
                {...form.getInputProps("password")}
                disabled
              />
            </div>

            <div className={Style.inputWrap}>
              <TextInput
                label="Telefon raqam"
                placeholder="Telefon raqamingizni kiriting"
                {...form.getInputProps("phone_number")}
                size="md"
                disabled
              />

              {user?.email ? null : <GooglePhone />}
            </div>
          </div>
        </form>
      </div>

      <div className={Style.buttons}>
        <Button
          loading={loading}
          className={Style.saveBtn}
          onClick={handleSubmit}
        >
          Saqlash
        </Button>
      </div>
    </div>
  );
}
