import { Container } from "@mantine/core";
import { Banner, BottomNavigation, Footer, Header } from "../../components";
import { AllProducts, Brands } from "../../sections";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Telmee telefon sotish va sotib olish platformasi</title>
        <meta
          name="description"
          content="Telmee telefon sotish va sotib olish uchun mo'ljallangan O'zbekistondagi birinchi va yagona platforma! Telefonningizni oson soting yoki o'zingizga qulay telefonni sotib oling"
        />
        <link rel="canonical" href="https://telmee.uz/" />
      </Helmet>
      <Header />
      <Container size="85rem">
        <Banner />
        <Brands />
        {/* <NewProduct /> */}
        <AllProducts />
        <Banner />
      </Container>
      <Footer />
      <BottomNavigation />
    </div>
  );
}
