import { GoogleLogin } from "react-google-login";
import { signInWithGoogle } from "../../modules";
import { gapi } from "gapi-script";

export default function Login() {
  const clientId =
    "100876200765-44b117em2k3fbmhourl0tebeeho8cet9.apps.googleusercontent.com";

  const onSuccess = () => {
    signInWithGoogle(gapi.auth.getToken().id_token);
  };

  const onFailure = (res: any) => {
    console.log("LOGIN Falied", res);
  };

  return (
    <div id="signInButton">
      <GoogleLogin
        clientId={clientId}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        render={({ onClick }) => (
          <button
            onClick={onClick}
            style={{
              width: "195px",
              height: "56px",
              background: "none",
              border: "none",
              color: "#8291a6",
              cursor: "pointer",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.54249 10.2667C4.54249 9.61712 4.65272 8.9944 4.84947 8.41031L1.40575 5.83667C0.734585 7.17031 0.356445 8.67303 0.356445 10.2667C0.356445 11.8589 0.73412 13.3608 1.40435 14.6935L4.84621 12.1149C4.65133 11.5335 4.54249 10.913 4.54249 10.2667Z"
                fill="#FBBC05"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5895 4.35751C12.0314 4.35751 13.3337 4.85751 14.3569 5.67569L17.3337 2.7666C15.5197 1.22115 13.1942 0.266602 10.5895 0.266602C6.54578 0.266602 3.07044 2.52978 1.40625 5.8366L4.84997 8.41024C5.64346 6.05297 7.90532 4.35751 10.5895 4.35751Z"
                fill="#EB4335"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5895 16.1758C7.90532 16.1758 5.64346 14.4803 4.84997 12.123L1.40625 14.6962C3.07044 18.0035 6.54578 20.2667 10.5895 20.2667C13.0853 20.2667 15.4681 19.3994 17.2565 17.7744L13.9876 15.3012C13.0653 15.8699 11.9039 16.1758 10.5895 16.1758Z"
                fill="#34A853"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3563 10.2667C20.3563 9.67576 20.2633 9.0394 20.1238 8.44849H10.5889V12.3121H16.0772C15.8028 13.6294 15.0558 14.6421 13.987 15.3012L17.2558 17.7744C19.1344 16.068 20.3563 13.5262 20.3563 10.2667Z"
                fill="#4285F4"
              />
            </svg>
            <p>Google</p>
          </button>
        )}
      />
    </div>
  );
}
