import store from "store2";
import { IEntity } from "../modules/auth";
import { config } from "../config";

export const getSession = (): IEntity.Tokens =>
  store.get(config.api.tokensKEY) || {};

export const clearSession = () => store.remove(config.api.tokensKEY);

export const setSession = (tokens: IEntity.Tokens) =>
  store.set(config.api.tokensKEY, tokens);

export const getAccessToken = (): string | null => {
  const session = store.get(config.api.tokensKEY) || {};
  return session.access;
};

export const getRefreshToken = (): string | null => {
  const session = store.get(config.api.tokensKEY) || {};
  return session.refresh;
};
