import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mantine/core";
import NotReportSvg from "../../../assets/images/notReportSvg";

import Style from "./not-report.module.scss";

export default function NotReport() {
  const navigete = useNavigate();

  return (
    <>
      <div className={Style.notReportWrapper}>
        <div>
          <NotReportSvg />
        </div>
        <p className={Style.notReportTitle}>Siz hozircha e’lon joylamagansiz</p>
        <p className={Style.notReportText}>
          E’lon berish tugmasini bosing va o’zingiz sotmoqchi bo’lgan
          qurulmangiz ma’lumotlarini kiriting
        </p>
        <Button
          h={40}
          onClick={() => {
            navigete("/addProduct");
          }}
          className={Style.addReportInNotReport}
        >
          E’lon berish
        </Button>
      </div>
    </>
  );
}
