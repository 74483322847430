import { useEffect, useState } from "react";
import { Box } from "@mantine/core";

import { alert } from "../../../utils";
import { Product, SkeletonLoader } from "../../../components";
import { IEntity, getUsersLikes } from "../../../modules/main";
import { EmptyWishes } from "../../../components/empty-wishes";

import Style from "../my-wishes/my-wishes.module.scss";

export default function Wishes() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersLikes(setLoading);
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={Style.totalWishes}>
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Box>
            {products?.length === 0 ? (
              <EmptyWishes />
            ) : (
              <div className={Style.productsContainer}>
                {products.map((product) => (
                  <Product key={product.id} product={product} />
                ))}
              </div>
            )}
          </Box>
        )}
      </div>
    </>
  );
}
