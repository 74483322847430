import React from "react";
import { useState } from "react";

import SingleCard from "./single-card";
import { Box } from "@mantine/core";
import { Empty, SkeletonLoader } from "../../../components";
import { IEntity, getUsersBuckets } from "../../../modules/main";

import { alert } from "../../../utils";
import Style from "./my-cart.module.scss";

export default function MyCart() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<IEntity.SingleProduct[]>([]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setLoading(true);
      const productsData = await getUsersBuckets(setLoading);
      setProducts(productsData);
    } catch (error) {
      alert.error("Error getting products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className={Style.profileMyCard} mt={40}>
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Box>{products?.length === 0 ? <Empty /> : <SingleCard />}</Box>
        )}
      </Box>
    </>
  );
}
